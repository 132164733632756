import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CacheListComponent } from './cache-list/cache-list.component';
import { ExperimentCreateComponent } from './experiment-create/experiment-create.component';
import { ExperimentListComponent } from './experiment-list/experiment-list.component';
import { ExperimentRoutingModule } from './experiment.routing.module';
import { ExperimentService } from './experiment.service';
import { TestCaseListComponent } from './test-case-list/test-case-list.component';
import { TestSetDetailComponent } from './test-set-detail/test-set-detail.component';
import { TestSetListComponent } from './test-set-list/test-set-list.component';

@NgModule({
  declarations: [
    ExperimentListComponent,
    ExperimentCreateComponent,
    TestSetListComponent,
    TestSetDetailComponent,
    TestCaseListComponent,
    CacheListComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    FormsModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatChipsModule,
    MatTableModule,
    MatRadioModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTabsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatSnackBarModule,
    MatCardModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTableModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatInputModule,
    MatAutocompleteModule,

    ExperimentRoutingModule,

    NgxJsonViewerModule
  ],
  providers: [
    ExperimentService
  ],
  entryComponents: [
  ],
  bootstrap: [],
  exports: [
    ExperimentListComponent
  ]
})
export class ExperimentModule { }

