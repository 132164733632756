import { Component } from '@angular/core';
import { TaskService } from '../task.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Task } from '../task.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { palexy } from '../../generated/bundle';
import { DateService } from '../../common/date';
import { debounceTime, filter } from 'rxjs/operators';

@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.css']
})
export class TaskEditComponent {

  taskEditForm: FormGroup;
  isSavingInProgress = false;
  errorMessage = '';
  editTask: Task;

  hours = Array.from(Array(24).keys())
  HakuhodoEditTrafficFields = {
    visit: 'VISIT',
    passBy: 'PASS_BY',
  }

  taskTypes = [
    {
      name: 'For Test Only',
      value: 'TYPE_TEST'
    },
    {
      name: 'Extract Production Result to Debug',
      value: 'TYPE_DEBUG_PERSON'
    },
    {
      name: 'Extract Experiment Result to Debug',
      value: "TYPE_DEBUG_EXPERIMENT"
    },
    {
      name: 'Export hakuhodo',
      value: "TYPE_EXPORT_HAKUHODO"
    },
    {
      name: 'Join Hakuhodo partial merging results',
      value: "TYPE_HAKUHODO_JOIN_PARTIAL_MERGING_RESULTS"
    },
    {
      name: 'Edit Hakuhodo traffic',
      value: "TYPE_HAKUHODO_EDIT_TRAFFIC",
    },
    {
      name: "Extrapolate Hakuhodo demography",
      value: "TYPE_HAKUHODO_EXTRAPOLATE_DEMOGRAPHY"
    },
    {
      name: 'Export osools event',
      value: "TYPE_EXPORT_OSOOL_EVENTS"
    },
  ]

  constructor(
    private taskService: TaskService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private dateService: DateService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.editTask = new Task();
  }

  createForm() {
    this.taskEditForm = this.fb.group({
      displayName: ['', Validators.required],
      taskType: ['', Validators.required],

      debugPerson: this.fb.group({
        selectedDate: ['', Validators.required],
        startHour: ['', Validators.required],
        endHour: ['', Validators.required],
        storeId: ['', Validators.required],
      }),

      hakuhodo: this.fb.group({
        startDateIdInclusive: ['', Validators.required],
        endDateIdInclusive: ['', Validators.required],
        storeId: ['', Validators.required],
      }),

      hakuhodoExportTask: this.fb.group({
        directionalPassByStoreId: ['', Validators.required],
        keepSectionVisitOnly: [''],
      }),

      hakuhodoEditTrafficTask: this.fb.group({
        edits: [[], Validators.required],
        rawText: [''],
      }),

      exportOsool: this.fb.group({
        startDateIdInclusive: ['', Validators.required],
        endDateIdInclusive: ['', Validators.required],
      }),
    });

    this.taskEditForm.get("taskType").valueChanges.subscribe((taskType) => {
      this.errorMessage = "";
      this.taskEditForm.get("debugPerson").disable();
      this.taskEditForm.get("exportOsool").disable();
      this.taskEditForm.get("hakuhodo").disable();
      this.taskEditForm.get("hakuhodoExportTask").disable();
      this.taskEditForm.get("hakuhodoEditTrafficTask").disable();
      switch (taskType) {
        case "TYPE_DEBUG_PERSON":
          this.taskEditForm.get("debugPerson").enable();
          break;
        case "TYPE_EXPORT_HAKUHODO":
          this.taskEditForm.get("hakuhodo").enable();
          this.taskEditForm.get("hakuhodoExportTask").enable();
          break;
        case "TYPE_EXPORT_OSOOL_EVENTS":
          this.taskEditForm.get("exportOsool").enable();
          break;
        case "TYPE_HAKUHODO_EDIT_TRAFFIC":
          this.taskEditForm.get("hakuhodo").enable();
          this.taskEditForm.get("hakuhodoEditTrafficTask").enable();
          break;
        case "TYPE_HAKUHODO_JOIN_PARTIAL_MERGING_RESULTS":
        case "TYPE_HAKUHODO_EXTRAPOLATE_DEMOGRAPHY":
          this.taskEditForm.get("hakuhodo").enable();
          break;
      }
    });

    this.taskEditForm.get("hakuhodoEditTrafficTask").get("rawText").valueChanges.pipe(
      filter(() => this.taskEditForm.get("hakuhodoEditTrafficTask").get("rawText").enabled),
      debounceTime(500),
    ).subscribe(() => {
      this.parseRawEditTrafficText();
    });
  }

  private prepareSaveTask(): Task {
    const formModel = this.taskEditForm.value;
    const newTask: Task = {
      ...this.editTask,
    }
    newTask.displayName = formModel.displayName as string;
    newTask.taskType = formModel.taskType;

    const advInfo = palexy.sherlock.v1.TaskAdvanceInfo.create();
    if (newTask.taskType == 'TYPE_DEBUG_PERSON') {
      const personFilter = palexy.sherlock.v1.PersonFilter.create();
      personFilter.storeId = formModel.debugPerson.storeId as number;
      const startHour = formModel.debugPerson.startHour as number;
      const endHour = formModel.debugPerson.endHour as number;
      const selectedDate = formModel.debugPerson.selectedDate as Date;
      personFilter.startDateTime = this.dateService.convertToPalexyStandardString(
        this.dateService.getDateWithHour(selectedDate, startHour));
      personFilter.endDateTime = this.dateService.convertToPalexyStandardString(
        this.dateService.getDateWithHour(selectedDate, endHour));

      const debugPersonAdvanceInfo = palexy.sherlock.v1.DebugPersonAdvanceInfo.create();
      debugPersonAdvanceInfo.personFilter = personFilter;
      advInfo.debugPersonAdvanceInfo = debugPersonAdvanceInfo;
    } else if (newTask.taskType == 'TYPE_EXPORT_HAKUHODO') {
      advInfo.exportHakuhodoAdvanceInfo = palexy.sherlock.v1.ExportHakuhodoAdvanceInfo.create({
        startDateIdInclusive: Number(formModel.hakuhodo.startDateIdInclusive),
        endDateIdInclusive: Number(formModel.hakuhodo.endDateIdInclusive),
        storeId: Number(formModel.hakuhodo.storeId),
        directionalPassByStoreId: Number(formModel.hakuhodoExportTask.directionalPassByStoreId),
        keepSectionVisitOnly: Boolean(formModel.hakuhodoExportTask.keepSectionVisitOnly),
      });
    } else if (newTask.taskType == 'TYPE_HAKUHODO_JOIN_PARTIAL_MERGING_RESULTS') {
      advInfo.hakuhodoJoinPartialMergingResultsAdvanceInfo = palexy.sherlock.v1.HakuhodoJoinPartialMergingResultsAdvanceInfo.create({
        startDateIdInclusive: Number(formModel.hakuhodo.startDateIdInclusive),
        endDateIdInclusive: Number(formModel.hakuhodo.endDateIdInclusive),
        storeIds: [Number(formModel.hakuhodo.storeId)],
      });
    } else if (newTask.taskType == 'TYPE_HAKUHODO_EDIT_TRAFFIC') {
      advInfo.hakuhodoEditTrafficAdvanceInfo = palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.create({
        trafficEdits: (formModel.hakuhodoEditTrafficTask.edits as any[])
          .filter(info => info.field == 'VISIT')
          .map(info => (
          palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.TrafficEdit.create({
            storeId: Number(info.storeId),
            dateId: Number(info.dateId),
            value: Number(info.value),
          })
        )),
        passByEdits: (formModel.hakuhodoEditTrafficTask.edits as any[])
          .filter(info => info.field == 'PASS_BY')
          .map(info => (
          palexy.sherlock.v1.HakuhodoEditTrafficAdvanceInfo.PassByEdit.create({
            storeId: Number(info.storeId),
            dateId: Number(info.dateId),
            value: Number(info.value),
            hour: Number(info.hour),
          })
        )),
      });
    } else if (newTask.taskType == 'TYPE_HAKUHODO_EXTRAPOLATE_DEMOGRAPHY') {
      advInfo.hakuhodoExtrapolateDemographyAdvanceInfo = palexy.sherlock.v1.HakuhodoExtrapolateDemographyAdvanceInfo.create({
        startDateIdInclusive: Number(formModel.hakuhodo.startDateIdInclusive),
        endDateIdInclusive: Number(formModel.hakuhodo.endDateIdInclusive),
        storeId: Number(formModel.hakuhodo.storeId),
      });
    } else if (newTask.taskType == 'TYPE_EXPORT_OSOOL_EVENTS') {
      advInfo.exportOsoolEventsAdvanceInfo = palexy.sherlock.v1.ExportOsoolEventsAdvanceInfo.create({
        startDateIdInclusive: Number(formModel.exportOsool.startDateIdInclusive),
        endDateIdInclusive: Number(formModel.exportOsool.endDateIdInclusive),
      });
    }
    newTask.taskAdvanceInfo = palexy.sherlock.v1.TaskAdvanceInfo.toObject(advInfo);
    return newTask;
  }

  onSubmit() {
    this.errorMessage = '';
    const newTask = this.prepareSaveTask();
    this.isSavingInProgress = true;
    this.taskService.createTask(newTask).subscribe((_) => {
      this.snackBar.open('Task created successfully', null, { duration: 2000});
      this.isSavingInProgress = false;
      this.router.navigate(['tasks']);
    }, err => {
      this.isSavingInProgress = false;
      console.error(err);
      this.errorMessage = err.error.message;
    });
  }

  parseRawEditTrafficText() {
    try {
      this.errorMessage = '';
      const value: string = this.taskEditForm.get("hakuhodoEditTrafficTask").get("rawText").value;
      if (!value) return;

      const lines = value.split('\n');
      const values = lines
        .filter(line => !!line)
        .map(line => {
        // split by tab and spaces
        const lineValues = line.split(/\s+/).filter(value => value != '');
        if (lineValues.length == 0) return;
        const field = lineValues[0].toUpperCase();
        if (field == this.HakuhodoEditTrafficFields.visit) {
          if (lineValues.length != 4) {
            throw new Error(`Invalid line: ${line}`);
          }
          return {
            storeId: lineValues[1],
            dateId: lineValues[2],
            field: field,
            value: lineValues[3],
          }
        } else if (field == this.HakuhodoEditTrafficFields.passBy) {
          if (lineValues.length != 5) {
            throw new Error(`Invalid line: ${line}`);
          }
          return {
            storeId: lineValues[1],
            dateId: lineValues[2],
            hour: lineValues[3],
            field: field,
            value: lineValues[4],
          }
        } else {
          throw new Error(`Invalid line: ${line}`);
        }
      });
      this.taskEditForm.get('hakuhodoEditTrafficTask').get('edits').setValue(values);
    } catch (err) {
      this.errorMessage = err.message;
    }
  }
}


