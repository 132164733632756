<div class="toolbar">
  <button mat-raised-button color="primary" (click)="showCreateTaskView()">CREATE TASK</button>

  <mat-form-field>
    <mat-select placeholder="Task State" [(value)]="taskStatus" (selectionChange)="fetchTasks()">
      <mat-option *ngFor="let opt of TASK_STATUS" [value]="opt">
        {{opt}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-select placeholder="Task Type" [(value)]="taskType" (selectionChange)="fetchTasks()">
      <mat-option *ngFor="let opt of TASK_TYPE" [value]="opt">
        {{opt}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>


<mat-table [dataSource]="dataSource" class="mat-elevation-z8 task-list-table">

  <ng-container matColumnDef="displayName">
    <mat-header-cell *matHeaderCellDef> Display Name </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.displayName}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <a [href]="'./' + element.name">{{element.name}}</a> </mat-cell>
  </ng-container>

  <ng-container matColumnDef="taskType">
    <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.taskType}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="taskStatus">
    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <span
        [ngClass]="{'done': element.taskStatus === 'STATUS_DONE', 'fail' : element.taskStatus === 'STATUS_FAILED' }">
        {{element.taskStatus}}
      </span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="createTime">
    <mat-header-cell *matHeaderCellDef> Create Time </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.createTime | date: 'short'}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="doneTime">
    <mat-header-cell *matHeaderCellDef> Done Time </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.endProcessingTime | date: 'short'}} </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row (click)="selectRow(row)" mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
