import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { palexy } from '../../generated/bundle';
import { StoreConfigItem } from '../storeconfig.model';
import { StoreConfigService } from '../storeconfig.service';
import { TaskService } from '../task.service';
import { UpdateToProductionDialogComponent } from './update-to-production-dialog.component';

@Component({
  selector: 'app-apply-config-btn',
  templateUrl: './apply-config-btn.component.html',
  styleUrls: ['./apply-config-btn.component.css']
})
export class ApplyconfigBtnComponent {

  @Input() config: StoreConfigItem;
  isProcessing= false;

  configName = '';

  constructor(
    private taskService: TaskService, 
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private storeConfigService: StoreConfigService
  ) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const configName = params.get('configName');
      this.configName = 'configs/' + configName;
    });
  }

  applyConfig() {
    if (this.isProcessing) return;
    
    if(confirm(`Are you sure you want to run this config ?`)) {
      this.isProcessing = true;
      this.taskService.createPostMergeTasksForStoreConfig(this.config).subscribe(_ => {
        this.isProcessing = false;
        location.reload();
      });
    }
  }

  calculateAccuracy() {
    if (this.isProcessing) return;
    
    if(confirm(`Are you sure you want to calculate accuracy for this config? This should only be done after all post merge task has completed`)) {
      this.isProcessing = true;
      let request = palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest.create();
      request.storeConfigId = +this.config.storeConfigId;
      request.postMergeStoreConfigItemId = +this.config.id;
      this.taskService.createAccuracyTaskForStoreConfig(request).subscribe(_ => {
        location.reload();
      })
    }
  }

  deleteConfig() {
    if (this.isProcessing) return;

    if(confirm(`Are you sure you want to delete this config ?`)) {
      this.isProcessing = true;
      this.storeConfigService.deleteStoreConfigItem(this.config.name).subscribe(_ => {
        this.isProcessing = false;
        location.reload();
      });
    }

  }

  showUpdateToProductionDialog() {
    const dialogRef = this.dialog.open(UpdateToProductionDialogComponent, {
      height: '600px',
      width: '600px',
      data: this.config
    });

    dialogRef.afterClosed().subscribe(result => {
    });    
  }
}


