import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { palexy } from '../../../generated/bundle';
import { Task } from '../../task.model';

@Component({
  selector: 'app-post-merge-result',
  templateUrl: './post-merge-result.component.html',
  styleUrls: ['./post-merge-result.component.css']
})
export class PostMergeResultComponent implements OnInit {

  _taskResult: palexy.sherlock.v1.ISherlockTaskResult;

  @Input() task: Task;

  detailResults: palexy.sherlock.v1.IDetailResult[];

  showOtherAction = false;

  @Input()
  set taskResult(result: palexy.sherlock.v1.ISherlockTaskResult) {
    this.detailResults = result.coreTaskResult.postMergeResult.debugPersonResult.extractBbResult.detailResults.sort(
      (r1, r2) => +r1.startTime.substring(9, 15) - +r2.startTime.substring(9, 15))
    this._taskResult = result
  }

  dataSource;

  constructor(
    private dialog: MatDialog
  ) {}

  ngOnInit() {


  }
}


