import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Task } from '../../../task.model';

@Component({
  selector: 'app-list-accuracy-result',
  templateUrl: './list-accuracy-result.component.html',
  styleUrls: ['./list-accuracy-result.component.css']
})
export class ListAccuracyResultComponent {

  displayedColumns: string[] = ['displayName', 'accuracy', 'actions'];

  dataSource;

  _tasks: Task[] = [];

  @Input()
  set tasks(tasks: Task[]) {
    this._tasks = tasks;
    this.dataSource = new MatTableDataSource(this._tasks);
  }

  constructor(
    private router: Router,
  ) {

  }

  selectRow(row) {
    this.router.navigate([row.name]);
  }
}
