<div class="dialog-container">
  <div class="dialog-header">
    <h2 mat-dialog-title>Create QC Plan Items</h2>
  </div>

  <div class="form">
    <mat-form-field class="field-full-width">
      <mat-select
        [formControl]="companyControl"
        placeholder="Select company to filter stores of that company"
        (selectionChange)="selectCompany($event.value)">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="companyFilterControl"
            placeholderLabel="Company"
            noEntriesFoundLabel="No results found">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
          {{ company.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="field-full-width">
      <mat-chip-list #chipList aria-label="Store selection">
        <mat-chip
          *ngFor="let store of selectedStores"
          [selectable]="false"
          [removable]="true"
          (removed)="removeStore(store)">
          {{ store.displayName }}
        <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          placeholder="Stores"
          #storeInput
          [formControl]="storeControl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          (click)="showStoreOptions()">
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selecteStore($event)">
        <mat-option *ngFor="let store of filteredStores | async" [value]="store.displayName">
          {{ store.displayName }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="field-full-width">
      <mat-label>Date range</mat-label>
      <mat-date-range-input [rangePicker]="dateRangePicker" required>
        <input matStartDate placeholder="Start date" [formControl]="startDateControl" readonly required>
        <input matEndDate placeholder="End date" [formControl]="endDateControl" readonly required>
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
      <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
    </mat-form-field>

    <div class="error" *ngIf="errorMessage">
      Error: {{ errorMessage }}
    </div>
  </div>

  <div class="dialog-footer">
    <button mat-raised-button (click)="cancel()">Cancel</button>
    <button mat-raised-button mat-primary color="primary"
      (click)="submit()"
      [disabled]="!(startDateControl.valid && endDateControl.valid && selectedStores.length > 0)">
      Create
    </button>
  </div>
</div>
