<div *ngIf="isLoading" class="centered">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<div class="form-group">

    <form class="creation-form" [formGroup]="createTestCaseForm" (ngSubmit)="createTestCase()">
        <mat-form-field class="field-full-width">
            <input type="number" matInput placeholder="QC File ID" formControlName="qcFileId" required>
        </mat-form-field>
        <br/>

        <mat-form-field class="field-full-width description-wrapper">
            <textarea class="description" matInput placeholder="Description" formControlName="description"></textarea>
        </mat-form-field>
        <br/>

        <mat-checkbox formControlName="includeTrackingDataAsCache">Include tracking data as cache</mat-checkbox>
        <br/>

        <mat-form-field class="field-full-width">
            <mat-select formControlName="selectedCacheId" placeholder="Cache">
                <mat-option *ngFor="let cache of caches" [value]="cache.id">
                    {{ cache.displayName }} - {{ cache.cacheYoloVersion }} - {{ cache.cacheReidVersion }} - {{ cache.cacheAgeGroupGenderVersion }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <br/>

        <button class="main-btn" mat-raised-button color="primary" type="submit" [disabled]="isLoading || createTestCaseForm.pristine || createTestCaseForm.status != 'VALID'">CREATE</button>
    </form>
</div>
<div class="error-message">
    {{errorMessage}}
</div>

<br/>

<mat-table #table [dataSource]="dataSource" class="mat-elevation-z8 task-list-table">
    <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="storeId">
        <mat-header-cell *matHeaderCellDef> Store ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.storeId}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateId">
        <mat-header-cell *matHeaderCellDef> Date ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.dateId}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="qcFileId">
        <mat-header-cell *matHeaderCellDef> QC File ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.qcFileId}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="numVideos">
        <mat-header-cell *matHeaderCellDef> Num Videos </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.videos.videos.length}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createTime">
        <mat-header-cell *matHeaderCellDef> Create Time </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createTimeMs | date:'medium'}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>