import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, ActivationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { palexy } from '../generated/bundle';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmailService {
  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }

  sendSimpleMessage = (request: palexy.streaming.v1.SendSimpleMessageRequest): Observable<palexy.streaming.v1.SendSimpleMessageResponse> =>
    this.http
      .post(`/api/v1/email:sendSimpleMessage`, request)
      .pipe(map(resp => palexy.streaming.v1.SendSimpleMessageResponse.fromObject(resp)));

}
