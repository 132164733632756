<div>
    <div>
        <a href="{{_taskResult.coreTaskResult.postMergeResult.debugPersonResult.extractBbResult.allBbDataLink}}">
      All BB Link
    </a>
    </div>

    <div>
        <a href="{{_taskResult.coreTaskResult.postMergeResult.debugPersonResult.extractBbResult.reconstructedMergeFileLink}}">
      Merge File Link
    </a>
    </div>
</div>

<mat-checkbox [(ngModel)]="showOtherAction">Show other actions (pickup, interaction)</mat-checkbox>

<div class="person-list">
    <div class="one-person mat-elevation-z2" *ngFor="let item of detailResults">
        <div class="person-header">

            {{item.personId}}, {{item.gender}}, {{item.ageRange}}
            <br /> {{item.startTime | slice:9:11}}:{{item.startTime | slice:11:13}}:{{item.startTime | slice:13:15}} - {{item.endTime | slice:9:11}}:{{item.endTime | slice:11:13}}:{{item.endTime | slice:13:15}}, {{item.durationInSeconds}} seconds, {{item.totalBb}}
            bb
        </div>

        <div class="person-actions">
            <div class="action-item" *ngFor="let entry of item.actionSummaryEntries">
                <div *ngIf="entry.actionType == 'visit' || showOtherAction">
                    <div>{{entry.actionType}}</div>
                    <div class="list-images">
                        <div class="action-image" *ngFor="let imagePath of entry.bbImageLinks">
                            <img src="{{imagePath}}">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>