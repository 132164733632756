<div>
  <a mat-raised-button href="merging_config_presets/new">CREATE PRESET</a>
</div>

<div>
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Id </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="['./', element.id]">
          {{element.name}}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="companyIds">
      <th mat-header-cell *matHeaderCellDef> Company </th>
      <td mat-cell *matCellDef="let element">
        <span *ngFor="let compId of element.companyIds">
          {{!!companies.get(compId) ? companies.get(compId) : compId}},
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="storeTypes">
      <th mat-header-cell *matHeaderCellDef> StoreTypes </th>
      <td mat-cell *matCellDef="let element">
        <span *ngFor="let t of element.storeTypes">
          {{t | mapVToK: StoreType}},
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="countingTypes">
      <th mat-header-cell *matHeaderCellDef> CountingTypes </th>
      <td mat-cell *matCellDef="let element">
        <span *ngFor="let t of element.countingTypes">
          {{t | mapVToK: CountingType}},
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef> Note </th>
      <td mat-cell *matCellDef="let element"> {{element.note}} </td>
    </ng-container>

    <ng-container matColumnDef="isEnabledForSuggestion">
      <th mat-header-cell *matHeaderCellDef> Suggest </th>
      <td mat-cell *matCellDef="let element"> {{element.isEnabledForSuggestion ? '✅': '❌'}} </td>
    </ng-container>

    <ng-container matColumnDef="lastUpdateTimeMs">
      <th mat-header-cell *matHeaderCellDef> LastUpdate </th>
      <td mat-cell *matCellDef="let element"> {{element.lastUpdateTimeMs | date}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="warn" (click)="onDelete(element)">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="onCopy(element)">
          <mat-icon>content_copy</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
