import { Component, Input, OnInit } from "@angular/core";
import { palexy } from "../../generated/bundle";
import { StoreConfigService } from "../storeconfig.service";

@Component({
  selector: "app-merging-config-form",
  templateUrl: "./merging-config-form.component.html",
  styleUrls: ["./merging-config-form.component.css"],
})
export class MergingConfigFormComponent implements OnInit {
  @Input() disabled = false;

  model: palexy.streaming.v1.MergingConfig;
  defaultMergingConfig: palexy.streaming.v1.IMergingConfig;

  constructor(private storeConfigService: StoreConfigService) {
    this.setConfig(palexy.streaming.v1.MergingConfig.create());
  }

  async ngOnInit() {
    this.defaultMergingConfig = await this.storeConfigService
      .getDefaultMergingConfig()
      .toPromise();
    this.normalize(this.defaultMergingConfig);
  }

  setConfig(input: palexy.streaming.v1.IMergingConfig): void {
    this.model = palexy.streaming.v1.MergingConfig.fromObject(
      palexy.streaming.v1.MergingConfig.toObject(
        palexy.streaming.v1.MergingConfig.fromObject(input),
        { defaults: true }
      )
    );
    this.normalize(this.model);
  }

  exportConfig() {
    return palexy.streaming.v1.MergingConfig.fromObject(this.model.toJSON());
  }

  private normalize(model: palexy.streaming.v1.IMergingConfig) {
    if (!model.outlierFilterConfig) {
      model.outlierFilterConfig =
        palexy.streaming.v1.OutlierFilteringConfig.create();
    }
    if (!model.outlierFilterConfig.staticObjectFilterConfig) {
      model.outlierFilterConfig.staticObjectFilterConfig =
        palexy.streaming.v1.StaticObjectFilteringConfig.create();
    }
    if (!model.runMallMergingConfig) {
      model.runMallMergingConfig =
        palexy.streaming.v1.RunMallMergingConfig.create();
    }
    if (!model.minimalCameraCountingConfig) {
      model.minimalCameraCountingConfig =
        palexy.streaming.v1.MinimalCameraCountingConfig.create();
    }
  }
}
