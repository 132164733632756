import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { google, palexy } from "../../generated/bundle";
import { StoreConfigService } from "../storeconfig.service";
import { CompanyService } from "../../common/company.service";
import { map } from "rxjs/operators";
import { UtilsService } from "../../common/utils";
import { MergingConfigFormComponent } from "../merging-config-form/merging-config-form.component";

@Component({
  selector: "app-merging-config-preset-item",
  templateUrl: "./merging-config-preset-item.component.html",
  styleUrls: ["./merging-config-preset-item.component.css"],
})
export class MergingConfigPresetItemComponent implements OnInit {
  mergingConfigPresetForm: FormGroup;
  private mergingConfigPreset: palexy.streaming.v1.MergingConfigPreset;
  isNew = true;

  readonly CountingType = palexy.streaming.v1.Store.CountingType;
  readonly StoreType = palexy.streaming.v1.Store.StoreType;
  companies: palexy.streaming.v1.ICompany[];

  @ViewChild("mergingConfigForm") mergingConfigForm: MergingConfigFormComponent;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private companyService: CompanyService,
    private storeConfigService: StoreConfigService
  ) {
    this.createForm();
  }

  async ngOnInit() {
    await Promise.all([this.preparePreset(), this.prepareCompanies()]);
  }

  private async preparePreset() {
    const presetId = this.route.snapshot.paramMap.get("preset_id");
    const copyFrom = Number.parseInt(
      this.route.snapshot.queryParamMap.get("copyFrom")
    );

    if (presetId) {
      this.mergingConfigPreset = await this.storeConfigService
        .getMergingConfigPreset(Number.parseInt(presetId))
        .toPromise();
      this.isNew = false;
      this.mergingConfigForm.setConfig(this.mergingConfigPreset.config);
    } else {
      this.mergingConfigPreset =
        palexy.streaming.v1.MergingConfigPreset.create();
      this.isNew = true;
      this.copyFrom(copyFrom);
    }
    this.mergingConfigPresetForm.patchValue(this.mergingConfigPreset);
    this.mergingConfigPresetForm.markAsPristine();
  }

  private async copyFrom(presetId: number) {
    if (!Number.isInteger(presetId)) return;

    const src = await this.storeConfigService
      .getMergingConfigPreset(presetId)
      .toPromise();
    this.mergingConfigForm.setConfig(src.config);
    this.mergingConfigPresetForm.patchValue({
      note: `Copied from preset ${
        src.name
      } ($author, ${new Date().toDateString()})`,
    });
  }

  private async prepareCompanies() {
    this.companies = await this.companyService
      .getCompanies()
      .pipe(map((companies) => companies.companies))
      .toPromise();
    this.companies = this.companies.sort((a, b) => a.name.localeCompare(b.name));
  }

  private createForm() {
    this.mergingConfigPresetForm = this.fb.group({
      name: ["", Validators.required],
      companyIds: [[]],
      storeTypes: [[]],
      countingTypes: [[]],
      note: [""],
      isEnabledForSuggestion: [false, Validators.required],
    });
  }

  async onSubmit() {
    try {
      await this.doSubmit();
      this.router.navigate(["/merging_config_presets"]);
    } catch (error) {
      this.utilsService.showMessage(error?.error?.message ?? error);
      console.error(error);
    }
  }

  private async doSubmit() {
    const updatedPreset = palexy.streaming.v1.MergingConfigPreset.create({
      ...this.mergingConfigPreset,
      ...this.mergingConfigPresetForm.value,
    });

    if (this.isNew) {
      updatedPreset.config = this.mergingConfigForm.exportConfig();
      await this.storeConfigService
        .createMergingConfigPreset(updatedPreset)
        .toPromise();
    } else {
      const request =
        palexy.streaming.v1.UpdateMergingConfigPresetRequest.create({
          mergingConfigPreset: updatedPreset,
          updateMask: google.protobuf.FieldMask.create({
            paths: [
              "name",
              "companyIds",
              "storeTypes",
              "countingTypes",
              "note",
              "isEnabledForSuggestion",
            ],
          }),
        });
      await this.storeConfigService
        .updateMergingConfigPreset(request)
        .toPromise();
    }
  }
}
