import { Component, OnInit, Input } from '@angular/core';
import { ExperimentService } from '../experiment.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { palexy } from '../../generated/bundle';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-experiment-create',
  templateUrl: './experiment-create.component.html',
  styleUrls: ['./experiment-create.component.css']
})
export class ExperimentCreateComponent  {

  createExperimentForm: FormGroup;
  errorMessage = "";
  isLoading = false;
  caches: palexy.sherlock.v1.IProcessingResultCache[] = [];
  testSets: palexy.sherlock.v1.ITestSet[] = [];
  
  constructor(
    private experimentService: ExperimentService, 
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.createExperimentForm = this.fb.group({
      programmingName: ['', Validators.required],
      description: ['', Validators.required],
      videoProcessorVersion: ['', Validators.required],
      trackingProcessorVersion: ['', Validators.required],
      mergingProcessorVersion: ['', Validators.required],
      selectedCacheId: ['', Validators.required],
      testSetId: ['', Validators.required],
    });

    this.isLoading = true;

    forkJoin([
      this.experimentService.listCaches(),
      this.experimentService.listTestSets()
    ]).subscribe(responses => {
        this.caches = responses[0].caches;
        this.testSets = responses[1].testSets;
        this.isLoading = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isLoading = false;
    });
  }

  createExperiment() {
    this.errorMessage = "";

    const formModel = this.createExperimentForm.value;
    this.isLoading = true;

    let request = palexy.sherlock.v1.CreateExperimentRequest.create();
    let experiment = palexy.sherlock.v1.Experiment.create();

    experiment.programmaticName = formModel.programmingName as string;
    experiment.description = formModel.description as string;
    experiment.videoProcessorVersion = formModel.videoProcessorVersion as string;
    experiment.trackingProcessorVersion = formModel.trackingProcessorVersion as string;
    experiment.mergingProcessorVersion = formModel.mergingProcessorVersion as string;
    experiment.cacheId = formModel.selectedCacheId as number;

    request.experiment = experiment;
    request.testSetId = formModel.testSetId as number;

    this.experimentService.createExperiment(request).subscribe(resp => {
      this.isLoading = false;
      this.router.navigate(['experiments']);
    }, err => {
      this.errorMessage = err.error.message;
      this.isLoading = false;
    })
  }
}


