import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { palexy } from "../generated/bundle";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  getCompanies = (): Observable<palexy.streaming.v1.ListCompaniesResponse> =>
    this.http
      .get("api/v1/companies")
      .pipe(map(palexy.streaming.v1.ListCompaniesResponse.fromObject));
}
