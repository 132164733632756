<div *ngIf="storeConfig">
    <div class="section section-title mat-title">{{storeConfig.storeData.displayName}}</div>
</div>

<div class="section debug-data-section">
    <div>
        <button mat-raised-button (click)="showCreateNewConfigItemView()">Create New Config</button>
        <button mat-raised-button (click)="showRerunMergingView()">Rerun Merging</button>
    </div>

    <app-config-list class="section-body" [configItems]="mergeConfigItems"></app-config-list>

</div>

<div class="section debug-data-section">
  <div class="section-title mat-title">Debug Data</div>
  <div>
    <button mat-raised-button (click)="showCreateDebugDialog()">Add Debug Data</button>
  </div>

  <app-list-debug-merge class="section-body" [tasks]="debugMergeTasks"></app-list-debug-merge>
</div>

<div class="section debug-data-section">
    <div class="section-title mat-title">Debug Experiment</div>
    <div>
        <button mat-raised-button (click)="showCreateDebugExperimentDialog()">Add Debug Experiment Data</button>
    </div>

  <app-list-debug-experiment class="section-body" [tasks]="debugExperimentTasks"> </app-list-debug-experiment>
</div>

<div class="section debug-data-section">
    <div class="section-title mat-title">Accuracy Data</div>
    <div>
        <button mat-raised-button (click)="getCurrentAccuracy()">Get Current Accuracy</button>
    </div>

    <app-list-accuracy-result class="section-body" [tasks]="accuracyTasks"></app-list-accuracy-result>
</div>

<!-- <div class="section debug-data-section">
    <div class="section-title mat-title">Post Merge Tasks</div>
    <app-list-debug-merge class="section-body" [tasks]="postMergeTasks"></app-list-debug-merge>
</div> -->

<div class="bottom">

</div>
