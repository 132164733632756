<div *ngIf="isLoading" class="centered">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<div class="form-group">

    <form class="creation-form" [formGroup]="createCacheForm" (ngSubmit)="createCache()">
        <mat-form-field class="field-full-width">
            <input matInput placeholder="Name" formControlName="displayName" required>
        </mat-form-field>
        <br/>

        <mat-form-field class="field-full-width">
            <input matInput placeholder="Yolo Version" formControlName="yoloVersion" required>
        </mat-form-field>
        <br/>

        <mat-form-field class="field-full-width">
            <input matInput placeholder="REID Version" formControlName="reidVersion" required>
        </mat-form-field>
        <br/>

        <mat-form-field class="field-full-width">
            <input matInput placeholder="Age Group Gender Version" formControlName="ageGroupGenderVersion" required>
        </mat-form-field>
        <br/>


        <button class="main-btn" mat-raised-button color="primary" type="submit" [disabled]="isLoading || createCacheForm.pristine || createCacheForm.status != 'VALID'">CREATE</button>
    </form>
</div>
<div class="error-message">
    {{errorMessage}}
</div>

<br/>

<mat-table #table [dataSource]="dataSource" class="mat-elevation-z8 task-list-table">

    <ng-container matColumnDef="displayName">
        <mat-header-cell *matHeaderCellDef> Display Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.displayName}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="yoloVersion">
        <mat-header-cell *matHeaderCellDef> Yolo Version </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.cacheYoloVersion}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="reidVersion">
        <mat-header-cell *matHeaderCellDef> ReID Version </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.cacheReidVersion}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ageGroupGenderVersion">
        <mat-header-cell *matHeaderCellDef> Age Group Gender Version </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.cacheAgeGroupGenderVersion}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createTime">
        <mat-header-cell *matHeaderCellDef> Create Time </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createTimeMs | date:'medium'}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>