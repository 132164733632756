import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { palexy } from '../generated/bundle';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CameraService {
  constructor(
    private http: HttpClient,
  ) {
  }

  listCamera = (storeId: number = null, includeNotProcessing: boolean = null, includeDisabled: boolean = null): Observable<palexy.streaming.v1.Camera[]> => {
    let param = [];
    if (storeId != null) param.push(`store_id=${storeId}`);
    if (includeNotProcessing != null) param.push(`include_not_processing=${includeNotProcessing}`);
    if (includeDisabled != null) param.push(`include_disabled=${includeDisabled}`);

    let url = '/api/v1/cameras';
    if (param.length > 0) url = url + '?' + param.join('&');
    return this.http
      .get(url)
      .pipe(
        map(resp => {
          let cameras = [];
          for (let item of resp['cameras']) {
            // NOTE: there is an error when using palexy.streaming.v1.ListCamerasResponse.fromObject because typescript can not parse google.protobuf.Timestamp from json string
            // TODO: add more field if needed
            let camera = palexy.streaming.v1.Camera.create();
            camera.name = item['name'];
            camera.rtspUrl = item['rtspUrl']
            camera.cameraId = item['cameraId'];
            camera.cameraDatabaseId = +item['cameraDatabaseId']
            camera.startTime = item['startTime'];
            camera.endTime = item['endTime'];
            camera.areaId = +item['areaId'];
            camera.storeId = +item['storeId'];
            cameras.push(camera);
          }
          return cameras;
        })
      );
    }
}
