import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { palexy } from '../../generated/bundle';
import { ExperimentService } from '../experiment.service';

@Component({
  selector: 'app-test-case-list',
  templateUrl: './test-case-list.component.html',
  styleUrls: ['./test-case-list.component.css']
})
export class TestCaseListComponent  {
  displayedColumns: string[] = ['id', 'description', 'storeId', 'dateId', 'numVideos', 'createTime'];
  testCases: palexy.sherlock.v1.IExperimentTestCase[] = [];
  caches: palexy.sherlock.v1.IProcessingResultCache[] = [];
  createTestCaseForm: FormGroup;

  dataSource: MatTableDataSource<palexy.sherlock.v1.IExperimentTestCase>;
  errorMessage = "";
  isLoading = false;

  constructor(
    private experimentService: ExperimentService, 
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.createTestCaseForm = this.fb.group({
      qcFileId: ['', Validators.required],
      description: [''],
      includeTrackingDataAsCache: [true, ],
      selectedCacheId: ['']
    });

    this.isLoading = true;

    this.experimentService.listTestCases().subscribe((response) => {
      this.isLoading = false;
      this.testCases = response.testCases;
      this.dataSource = new MatTableDataSource(this.testCases);
    });

    this.experimentService.listCaches().subscribe((response) => {
      this.caches = response.caches;
    })
  }

  createTestCase() {
    this.errorMessage = "";

    const formModel = this.createTestCaseForm.value;
    this.isLoading = true;

    let request = palexy.sherlock.v1.BatchCreateTestCasesRequest.create();

    request.qcFileIds.push(formModel.qcFileId as number);

    request.description = formModel.description as string;
    request.includeCurrentTrackingDataAsCache = (formModel.includeTrackingDataAsCache as boolean);

    if (request.includeCurrentTrackingDataAsCache) {
      request.resultCacheId = formModel.selectedCacheId as number;
      if (!request.resultCacheId) {
        this.isLoading = false;
        this.errorMessage = "Cache ID is required when including tracking data as cahe is true";
        return ;
      }
    } 

    this.experimentService.batchCreateTestCases(request).subscribe(resp => {
      this.isLoading = false;
      window.location.reload();
    }, err => {
      this.errorMessage = err.error.message;
      this.isLoading = false;
    })
  }
}


