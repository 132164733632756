import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { palexy } from "../generated/bundle";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ApiKeyService {

  constructor(private http: HttpClient) {
  }

  getAccountInfoByToken = (request: palexy.janus.v1.GetAccountInfoByTokenRequest): Observable<any> => {
    return this.http.post(`/api/janus/v1/apikey:getAccountInfoByToken`, request.toJSON(), {
      headers: {
        Authorization: `Bearer ${request.token}`
      }
    });
  }

  getCurrentAccount(): palexy.janus.v1.Account {
    let currentAccount = localStorage.getItem("account");
    if (currentAccount) {
      return palexy.janus.v1.Account.fromObject(JSON.parse(currentAccount));
    }
    else return null;
  }

  getCurrentToken(): string {
    let token = localStorage.getItem("token");
    if (token){
      return token;
    }
    else return null;
  }

  onAuthorized(token: string, account) {
    localStorage.setItem("token", token);
    localStorage.setItem("account", JSON.stringify(account));
  }

  onUnauthorized() {
    localStorage.removeItem("token");
    localStorage.removeItem("account");
  }

  getCurrentAccountByToken() {
    let token = this.getCurrentToken()
    const request = palexy.janus.v1.GetAccountInfoByTokenRequest.create();
    request.token = token;
    return this.getAccountInfoByToken(request);
  }
}
