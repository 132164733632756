import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { palexy } from '../generated/bundle';

@Injectable()
export class QcService {
  constructor(
    private http: HttpClient
  ) {
  }

  fetchQcFiles = (pageIndex: number = 0, pageSize: number = 0, excludeQcFileData: boolean = false): Observable<palexy.sherlock.v1.ListQcFilesResponse> =>
    this.http
      .get(`/api/sherlock/v1/qcfiles?page_token=${pageIndex}&page_size=${pageSize}&exclude_qc_file_data=${excludeQcFileData}`)
      .pipe(
        map(resp => palexy.sherlock.v1.ListQcFilesResponse.fromObject(resp))
      );

  uploadQcFile = (request: palexy.sherlock.v1.UploadQcFileResponse): Observable<palexy.sherlock.v1.UploadQcFileResponse> =>
    this.http
      .post(`/api/sherlock/v1/qcfiles:uploadFile`, request).pipe(map(resp => palexy.sherlock.v1.UploadQcFileResponse.fromObject(resp)));

  recalculateAccuracy = (request: palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest): Observable<palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse> =>
    this.http
      .post(`/api/sherlock/v1/accuracy:batchCalculateAndSaveAccuracy`, request)
      .pipe(map(resp => palexy.sherlock.v1.BatchCalculateAndSaveAccuracyResponse.fromObject(resp)));

  getQcFileById = (id): Observable<palexy.sherlock.v1.GetQcFileResponse> =>
    this.http
      .get(`/api/sherlock/v1/qcfiles/${id}`)
      .pipe(
        map(resp => palexy.sherlock.v1.GetQcFileResponse.fromObject(resp))
      );

  getAccuracyResults = (qcFileId): Observable<palexy.sherlock.v1.ListAccuracyResultsResponse> =>
    this.http
      .get(`/api/sherlock/v1/accuracyresults?qcFileId=${qcFileId}`)
      .pipe(
        map(resp => palexy.sherlock.v1.ListAccuracyResultsResponse.fromObject(resp))
      );

  deleteQcFileById = (id): Observable<palexy.sherlock.v1.DeleteQcFileResponse> =>
    this.http
      .delete(`/api/sherlock/v1/qcfiles/${id}`)
      .pipe(
        map(resp => palexy.sherlock.v1.DeleteQcFileResponse.fromObject(resp)));

  createQcPlanItem = (request: palexy.sherlock.v1.CreateQcPlanItemRequest): Observable<palexy.sherlock.v1.CreateQcPlanItemResponse> =>
    this.http
      .post(`/api/sherlock/v1/qcplanner/planitems`, request)
      .pipe(map(resp => palexy.sherlock.v1.CreateQcPlanItemResponse.fromObject(resp)));

  batchCreateQcPlanItems = (request: palexy.sherlock.v1.BatchCreateQcPlanItemsRequest): Observable<palexy.sherlock.v1.BatchCreateQcPlanItemsResponse> =>
    this.http
      .post(`/api/sherlock/v1/qcplanner/planitems:batchCreate`, request)
      .pipe(map(resp => palexy.sherlock.v1.BatchCreateQcPlanItemsResponse.fromObject(resp)));

  getPlanItems = (companyId: number, storeId: number = 0): Observable<palexy.sherlock.v1.ListQcPlanItemsResponse> =>
    this.http
      .get(`/api/sherlock/v1/qcplanner/planitems?companyId=${companyId}&storeId=${storeId}`)
      .pipe(
        map(resp => palexy.sherlock.v1.ListQcPlanItemsResponse.fromObject(resp))
      );

  deletePlanItem = (name: string): Observable<palexy.sherlock.v1.DeleteQcPlanItemResponse> =>
    this.http
      .delete(`/api/sherlock/v1/qcplanner/${name}`)
      .pipe(
        map(resp => palexy.sherlock.v1.DeleteQcPlanItemResponse.fromObject(resp)));

  createPeriodConfig = (request: palexy.sherlock.v1.CreateQcPeriodConfigRequest): Observable<palexy.sherlock.v1.CreateQcPeriodConfigResponse> =>
    this.http
      .post(`/api/sherlock/v1/qcplanner/qcperiodconfigs`, request)
      .pipe(map(resp => palexy.sherlock.v1.CreateQcPeriodConfigResponse.fromObject(resp)));

  getPeriodConfigs = (companyId: number): Observable<palexy.sherlock.v1.ListQcPeriodConfigsResponse> =>
    this.http
      .get(`/api/sherlock/v1/qcplanner/qcperiodconfigs?companyId=${companyId}`)
      .pipe(
        map(resp => palexy.sherlock.v1.ListQcPeriodConfigsResponse.fromObject(resp))
      );

  deletePeriodConfig = (name: string): Observable<palexy.sherlock.v1.DeleteQcPeriodConfigResponse> =>
    this.http
      .delete(`/api/sherlock/v1/qcplanner/${name}`)
      .pipe(
        map(resp => palexy.sherlock.v1.DeleteQcPeriodConfigResponse.fromObject(resp)));
}
