
import {throwError as observableThrowError, Observable} from 'rxjs';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable, NgZone} from "@angular/core";
import {PRIMARY_OUTLET, Router} from "@angular/router";
import {catchError} from "rxjs/operators";
import {ApiKeyService} from "./common/apikey.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private readonly unauthenticatedUrls = [
    '/login',
  ];

  constructor(
    private router: Router,
    private zone: NgZone,
    private apiKeyService: ApiKeyService
  ) {
  }

  private handleAuthError = (err): Observable<any> => {
    const p = this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET];
    let path = '/';
    if (p) path += p.segments.map(segment => String(segment)).join('/');
    if (!this.unauthenticatedUrls.includes(path) && err.status === 401) {
      this.zone.run(() => {
        alert(`Error: ${err.error.message}. Gonna redirect to login page.`);
        this.router.navigate(['/login'], {queryParams: {redirect: window.location.href}});
      });
      this.apiKeyService.onUnauthorized();
    }
    return observableThrowError(err);
  };

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let currentToken = this.apiKeyService.getCurrentToken();
    if (!req.headers.has(`Authorization`)){
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${currentToken}`
        }
      });
    }
    return next.handle(req).pipe(
      catchError(this.handleAuthError)
    );
  }
}
