import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TaskListComponent } from './task-list/task-list.component';
import { TaskEditComponent } from './task-edit/task-edit.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { StoreConfigListComponent } from './store-config-list/store-config-list.component';
import { StoreConfigEditComponent } from './store-config-edit/store-config-edit.component';
import { StoreConfigDetailComponent } from './store-config-detail/store-config-detail.component';
import { StoreConfigItemEditComponent } from './store-config-item-edit/store-config-item-edit.component';
import { MergingConfigPresetListComponent } from './merging-config-preset-list/merging-config-preset-list.component';
import { MergingConfigPresetItemComponent } from './merging-config-preset-item/merging-config-preset-item.component';


const appRoutes: Routes = [
    {
        path: 'tasks',
        component: TaskListComponent
    },
    {
        path: 'configs',
        component: StoreConfigListComponent
    },
    {
        path: 'configs/new',
        component: StoreConfigEditComponent
    },
    {
        path: 'configs/:configName',
        component: StoreConfigDetailComponent
    },
    {
        path: 'configs/:configName/items/new',
        component: StoreConfigItemEditComponent
    },
    {
        path: 'tasks/new',
        component: TaskEditComponent
    },
    {
        path: 'tasks/:taskName',
        component: TaskDetailComponent
    },
    {
        path: 'merging_config_presets',
        component: MergingConfigPresetListComponent
    },
    {
        path: 'merging_config_presets/new',
        component: MergingConfigPresetItemComponent
    },
    {
        path: 'merging_config_presets/:preset_id',
        component: MergingConfigPresetItemComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(
            appRoutes
        )
    ],
    providers: [],
    exports: [
        RouterModule
    ]
})
export class TaskRoutingModule { }
