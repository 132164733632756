import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { palexy } from '../../generated/bundle';
import { ExperimentService } from '../experiment.service';

@Component({
  selector: 'app-test-set-list',
  templateUrl: './test-set-list.component.html',
  styleUrls: ['./test-set-list.component.css']
})
export class TestSetListComponent  {
  displayedColumns: string[] = ['displayName', 'id', 'description', 'createTime'];
  testSets: palexy.sherlock.v1.ITestSet[] = [];
  createTestSetForm: FormGroup;

  dataSource: MatTableDataSource<palexy.sherlock.v1.ITestSet>;
  errorMessage = "";
  isLoading = false;

  constructor(
    private experimentService: ExperimentService, 
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.createTestSetForm = this.fb.group({
      displayName: ['', Validators.required],
      description: ['', Validators.required]
    });

    this.isLoading = true;

    this.experimentService.listTestSets().subscribe((response) => {
      this.isLoading = false;
      this.testSets = response.testSets;
      this.dataSource = new MatTableDataSource(this.testSets);
    });
  }

  createTestSet() {
    this.errorMessage = "";

    const formModel = this.createTestSetForm.value;
    this.isLoading = true;

    let request = palexy.sherlock.v1.CreateTestSetRequest.create();
    let testSet = palexy.sherlock.v1.TestSet.create();

    testSet.displayName = formModel.displayName as string;
    testSet.description = formModel.description as string;

    request.testSet = testSet;

    this.experimentService.createTestSet(request).subscribe(resp => {
      this.isLoading = false;
      window.location.reload();
    }, err => {
      this.errorMessage = err.error.message;
      this.isLoading = false;
    })
  }

  gotToDetail(testSetId) {
    this.router.navigate(['testsets/' + testSetId]);
  }
}


