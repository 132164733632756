<form class="edit-form" [formGroup]="updateHumanLabelLink" (ngSubmit)="onSubmit()">
    <div class="flex-container">
        <mat-form-field class="field-full-width">
            <input matInput placeholder="New Link" formControlName="humanLabelLink" required>
        </mat-form-field>
    </div>

    <button class="main-btn" mat-raised-button color="primary" type="submit" [disabled]="updateHumanLabelLink.pristine 
          || updateHumanLabelLink.status != 'VALID'
          || isSavingInProgress"> {{(isSavingInProgress) ? "SAVING" : "SAVE"}}</button>
</form>