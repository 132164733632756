import { Component } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Task } from "../task.model";
import { TaskService } from "../task.service";
import { palexy } from "../../generated/bundle";

@Component({
  selector: "app-task-list",
  templateUrl: "./task-list.component.html",
  styleUrls: ["./task-list.component.css"],
})
export class TaskListComponent {
  displayedColumns: string[] = [
    "displayName",
    "name",
    "taskType",
    "taskStatus",
    "createTime",
    "doneTime",
  ];
  dataSource: MatTableDataSource<Task> = new MatTableDataSource();

  taskStatus = "STATUS_UNKNOWN";
  taskType = "TYPE_UNKNOWN";

  readonly TASK_STATUS = Object.keys(
    palexy.sherlock.v1.SherlockTask.TaskStatus
  );
  readonly TASK_TYPE = Object.keys(palexy.sherlock.v1.SherlockTask.TaskType);

  constructor(private taskService: TaskService, private router: Router) {
    this.fetchTasks();
  }

  showCreateTaskView() {
    this.router.navigate(["tasks/new"]);
  }

  selectRow(row) {
    this.router.navigate([row.name]);
  }

  fetchTasks() {
    this.taskService
      .fetchTasks(this.taskStatus, null, this.taskType, null, 0, 200)
      .subscribe((response) => (this.dataSource.data = response.sherlockTasks));
  }
}
