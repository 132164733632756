<div *ngIf="isLoading" class="centered">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<h2>Rerun Merging</h2>

<form class="edit-form" *ngIf="rerunMergingForm" [formGroup]="rerunMergingForm" (ngSubmit)="onSubmit()">
  <mat-form-field class="field-full-width">
    <mat-chip-list #chipList aria-label="Stores" formArrayName="storeDisplayNames">
      <mat-chip *ngFor="let store of storeDisplayNamesFormArray.controls; let idx=index"
                [formGroupName]="idx"
                [selectable]="true"
                [removable]="true"
                (removed)="removeSelectedStore(idx)">
        {{store.value}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input #storeInput matInput placeholder="Store"
             [formControl]="storeControl"
             [matChipInputFor]="chipList"
             [matAutocomplete]="auto"
             (click)="showStoreOptions()">
    </mat-chip-list>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onStoreSelected($event)">
      <mat-option *ngFor="let store of filteredStores | async" [value]="store.displayName">
        {{ store.displayName }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="field-full-width">
    <input formControlName="dateIds" matInput placeholder="Date IDs" autocomplete="off" required>
    <mat-hint>Format: YYYYMMDD[-YYYYMMDD][,YYYYMMDD[-YYYYMMDD]]*, e.g. 20200601-20200607,20200610</mat-hint>
  </mat-form-field>


  <button mat-raised-button class="main-btn" type="submit" [disabled]="rerunMergingForm.pristine
      || rerunMergingForm.status != 'VALID'
      || isSavingInProgress"> {{(isSavingInProgress) ? "SUBMITTING" : "SUBMIT"}}</button>

  <button mat-raised-button color="secondary" type="button" [disabled]="isSavingInProgress"
          (click)="dialogRef.close()">CANCEL</button>

</form>

<span class="error-message">{{errorMessage}}</span>
