import { Pipe, PipeTransform } from "@angular/core";
import { palexy } from "../generated/bundle";


export function filterStore(value: string, stores: palexy.streaming.v1.IStore[]): palexy.streaming.v1.IStore[] {
  const filterValue = toSimpleForm(value);
  return stores.filter(store => toSimpleForm(store.displayName).includes(filterValue));
}

function toSimpleForm(value: string) {
  return value.toLowerCase().replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a")
    .replace(/[èéẹẻẽêềếệểễ]/g, "e")
    .replace(/[ìíịỉĩ]/g, "i")
    .replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o")
    .replace(/[ùúụủũưừứựửữ]/g, "u")
    .replace(/[ỳýỵỷỹ]/g, "y")
    .replace(/đ/g, "d");
}



@Pipe({
  name: 'mapVToK'
})
export class MapVToKPipe implements PipeTransform {
  transform(value, object) {
    return Object.keys(object).find(key => object[key] === value);
  }
}
