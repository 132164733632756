<div class="container">
  <div class="form-controller">
    <mat-form-field>
      <mat-select
        [formControl]="companyControl"
        placeholder="Company"
        (selectionChange)="selectCompany($event.value)">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="companyFilterControl"
            placeholderLabel="Company"
            noEntriesFoundLabel="No results found">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
          {{ company.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="store-control">
      <mat-select
        [formControl]="storeControl"
        placeholder="Store"
        (selectionChange)="selectStore($event.value)">
        <mat-option>
          <ngx-mat-select-search
            [formControl]="storeFilterControl"
            placeholderLabel="Store"
            noEntriesFoundLabel="No results found">
          </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let store of filteredStores | async" [value]="store">
          {{ store.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="form-controller">
    <button mat-raised-button color="secondary"
      (click)="showConfigArea=!showConfigArea">
      Show Period Config
    </button>
    <button mat-raised-button color="primary"
      (click)="openCreateQcPlanDialog()">
      Create QC Plan
    </button>
  </div>


  <div class="config-area" *ngIf="showConfigArea && !isLoading">
    <form class="form-controller" [formGroup]="createPeriodConfigForm" (ngSubmit)="createPeriodConfig()">
      <mat-form-field>
        <input formControlName="startingDate" matInput [matDatepicker]="pickerStartingDate" placeholder="Starting Date*">
        <mat-datepicker-toggle matSuffix [for]="pickerStartingDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerStartingDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <input type="number" matInput placeholder="Num days in period" formControlName="numDaysInPeriod">
      </mat-form-field>

      <mat-form-field>
        <input type="number" matInput placeholder="Percentage of stores" formControlName="percentageOfStores">
      </mat-form-field>

      <mat-form-field>
        <input type="number" matInput placeholder="Num qc days per store" formControlName="numQcDaysPerStore">
      </mat-form-field>

      <mat-form-field>
        <input matInput placeholder="Creator" formControlName="creator">
      </mat-form-field>
    </form>
    <button class="main-btn" mat-raised-button color="primary" type="submit"
      [disabled]="isLoading || createPeriodConfigForm.pristine || createPeriodConfigForm.status != 'VALID'">
      Create Period Config
    </button>

    <div class="error-message">
      {{periodConfigErrorMessage}}
    </div>


    <mat-table [dataSource]="configDataSource" class="mat-elevation-z8 task-list-table">

      <ng-container matColumnDef="configId">
        <mat-header-cell *matHeaderCellDef> Config ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="startingDateId">
        <mat-header-cell *matHeaderCellDef> Starting Date ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.startingDateId}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="numDaysInPeriod">
        <mat-header-cell *matHeaderCellDef> Num days in period </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.numDaysInPeriod}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="percentageOfStore">
        <mat-header-cell *matHeaderCellDef> Percentage of stores </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.percentageOfStore}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="numQcDaysPerStore">
        <mat-header-cell *matHeaderCellDef> Day per store </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.numQcDaysPerStore}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="creator">
        <mat-header-cell *matHeaderCellDef> Creator </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.creator}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createTime">
        <mat-header-cell *matHeaderCellDef> Create Time </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createTimeMs | date:'medium'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastUpdateTimeMs">
        <mat-header-cell *matHeaderCellDef> Update Time </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.lastUpdateTimeMs | date:'medium'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="plannedUntilDateId">
        <mat-header-cell *matHeaderCellDef>Planned Until </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.plannedUntilDateId}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="planDetailLastGenerateTimeMs">
        <mat-header-cell *matHeaderCellDef> Generate Time </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.planDetailLastGenerateTimeMs | date:'medium'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button class="main-btn" mat-button color="warn" (click)="deleteConfig(element)">DELETE</button>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="configDisplayedColumns"></mat-header-row>
      <mat-row mat-row *matRowDef="let row; columns: configDisplayedColumns;"></mat-row>
    </mat-table>
  </div>


  <!-- QC Item List -->
  <table class="table" *ngIf="!isLoading">
    <thead>
      <tr class="table-header">
        <th> Store Name </th>
        <th> Date </th>
        <th> Config Id </th>
        <th> Applied Time </th>
        <th> Create Time </th>
        <th> Action </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of qcItems" class="table-row">
        <td> {{ storeNameMap.get(item.storeId) }} </td>
        <td> {{ item.dateId }} </td>
        <td> {{ item.periodConfigId > 0 ? item.periodConfigId : 'Custom' }} </td>
        <td> {{ (item.isAppliedToVideoTable ? item.videoTableAppliedTimeMs : null) | date:'medium' }} </td>
        <td> {{ item.createTimeMs | date:'medium' }} </td>
        <td>
          <button mat-button color="warn"
            matTooltip="Delete"
            (click)="deletePlanItem(item)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>


  <div class="error-message">
    {{errorMessage}}
  </div>
</div>

<div *ngIf="isLoading" class="centered">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
