import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { palexy } from '../../generated/bundle';
import { ExperimentService } from '../experiment.service';

@Component({
  selector: 'app-experiment-list',
  templateUrl: './experiment-list.component.html',
  styleUrls: ['./experiment-list.component.css']
})
export class ExperimentListComponent  {
  displayedColumns: string[] = ['programmingName', 'description', 'cacheId', 'state', 'createTime', 'allInfo'];
  experiments: palexy.sherlock.v1.IExperiment[] = [];

  dataSource: MatTableDataSource<palexy.sherlock.v1.IExperiment>;
  errorMessage = "";
  isLoading = false;

  experimentStateEnum = palexy.sherlock.v1.Experiment.ExperimentState;

  constructor(
    private experimentService: ExperimentService, 
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.isLoading = true;

    this.experimentService.listExperiments().subscribe((response) => {
      this.isLoading = false;
      this.experiments = response.experiments;
      this.dataSource = new MatTableDataSource(this.experiments);
    });
  }

  gotoTestCases() {
    this.router.navigate(['testcases/']);
  }

  gotoTestSets() {
    this.router.navigate(['testsets/']);
  }

  gotoCaches() {
    this.router.navigate(['caches/']);
  }

  gotoCreateExperiment() {
    this.router.navigate(['experiments/create']);
  }
}


