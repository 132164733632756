import { Component, OnInit, Input } from '@angular/core';
import { TaskService } from '../task.service';
import { Router } from '@angular/router';
import { Task } from '../task.model';

@Component({
  selector: 'app-task-delete-btn',
  templateUrl: './task-delete-btn.component.html',
  styleUrls: ['./task-delete-btn.component.css']
})
export class DeleteTaskBtnComponent {

  @Input() task: Task;
  isProcessing= false;

  constructor(
    private taskService: TaskService, 
    private router: Router,
  ) {

  }

  deleteTask() {
    if (this.isProcessing) return;
    
    this.isProcessing = true;
    if(confirm(`Are you sure you want to delete task "${this.task.displayName}" ?`)) {
      this.taskService.deleteTask(this.task.name).subscribe(_ => {
        this.isProcessing = false;
        location.reload();
      });
    }
  }
}


