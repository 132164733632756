import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DateService } from '../../common/date';
import { ExperimentService } from "../../experiment/experiment.service";
import { palexy } from '../../generated/bundle';
import { TaskService } from '../task.service';
import ExperimentState = palexy.sherlock.v1.Experiment.ExperimentState;

@Component({
  selector: 'app-create-debug-experiment-dialog',
  templateUrl: './create-debug-experiment-dialog.component.html',
  styleUrls: ['./create-debug-experiment-dialog.component.css']
})
export class CreateDebugExperimentDialogComponent {
  createDebugDataForm: FormGroup;
  isSavingInProgress = false;

  errorMessage = "";
  isLoading = false;
  hours = Array.from(Array(24).keys());
  maxHours = Array.from(Array(16).keys());

  storeId: number;
  storeConfigId: number;
  caches: palexy.sherlock.v1.IProcessingResultCache[] = [];

  experiments: palexy.sherlock.v1.IExperiment[] = [];

  dataSource: MatTableDataSource<palexy.sherlock.v1.IExperiment>;

  constructor(
    public dialogRef: MatDialogRef<CreateDebugExperimentDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateService: DateService,
    private experimentService: ExperimentService,
    private taskService: TaskService) {
    this.storeId = data.storeId;
    this.storeConfigId = data.storeConfigId;
    this.experimentService.listExperiments().subscribe((response) => {
      this.isLoading = false;
      this.experiments = response.experiments;

      this.dataSource = new MatTableDataSource(this.experiments);
    });
    this.createForm();
  }

  createForm() {
    this.createDebugDataForm = this.fb.group({
      selectedDate: ['', Validators.required ],
      startHour: [10, Validators.required],
      endHour: [21, Validators.required],
      maxHoursToSplit: [3, Validators.required],
      experimentName: ['', Validators.required]
    });
  }

  onSubmit() {
    const formModel = this.createDebugDataForm.value;
    const request = palexy.sherlock.v1.CreateDebugTaskForExperimentRequest.create();
    request.startHour = formModel.startHour as number;
    request.endHour = formModel.endHour as number;
    request.maxHoursToSplit = formModel.maxHoursToSplit as number;
    request.storeId = +this.storeId ;
    request.storeConfigId = +this.storeConfigId;
    request.dateId = this.dateService.convertToPalexyStandardString(formModel.selectedDate).substring(0, 8);
    request.experimentName= formModel.experimentName as string;

    this.isSavingInProgress = true;
    this.taskService.createDebugTaskForExperiment(request).subscribe(_ => {
      this.isSavingInProgress = false;
      this.dialogRef.close({});
      location.reload();
    });
  }
}


