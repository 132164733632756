<div *ngIf="isLoading" class="centered">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<form class="form" *ngIf="form && !isLoading" [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field class="field-full-width">
    <mat-chip-list #chipList aria-label="Stores" formArrayName="storeDisplayNames">
      <mat-chip *ngFor="let store of storeDisplayNamesFormArray.controls; let idx=index"
                [formGroupName]="idx"
                [selectable]="true"
                [removable]="true"
                (removed)="removeSelectedStore(idx)">
        {{store.value}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input #storeInput matInput placeholder="Stores"
              [formControl]="storeControl"
              [matChipInputFor]="chipList"
              [matAutocomplete]="auto"
              (click)="showStoreOptions()">
    </mat-chip-list>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onStoreSelected($event)">
      <mat-option *ngFor="let store of filteredStores | async" [value]="store.displayName">
        {{ store.displayName }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="field-full-width">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate formControlName="startDate" placeholder="Start date">
      <input matEndDate formControlName="endDate" placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <button mat-raised-button class="main-btn" type="submit" [disabled]="form.pristine
    || form.status != 'VALID'
    || isSavingInProgress"> {{(isSavingInProgress) ? "SUBMITTING" : "SUBMIT"}}
  </button>

</form>

<span class="error-message">{{errorMessage}}</span>