<mat-table #table [dataSource]="dataSource" class="mat-elevation-z8 task-list-table">

    <ng-container matColumnDef="displayName">
        <mat-header-cell *matHeaderCellDef> Display Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.displayName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="taskType">
        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.taskType}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="taskStatus">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.taskStatus}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createTime">
        <mat-header-cell *matHeaderCellDef> Create Time </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createTime}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="doneTime">
        <mat-header-cell *matHeaderCellDef> Done Time </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.endProcessingTime}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <div class="action-list">
                <div *ngIf="element.taskStatus == 'STATUS_FAILED'">
                    <app-task-retry-btn [task]="element"></app-task-retry-btn>
                </div>
                <app-task-delete-btn [task]="element"></app-task-delete-btn>
            </div>

        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="humanLabelLink">
        <mat-header-cell *matHeaderCellDef> Links </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <a (click)="fetchThenDownloadTrackingResult(element)">All Tracking Results Link</a>
        </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
