import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';


import { DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';

import { HttpClientModule } from '@angular/common/http';
import { MomentModule } from 'angular2-moment';
import { ApiKeyService } from "./common/apikey.service";
import { DateService } from './common/date';
import { ExperimentModule } from './experiment/experiment.module';
import { LoginComponent } from "./login/login.component";
import { QcModule } from './qc/qc.module';
import { TaskModule } from './task/task.module';
import { UtilsService } from './common/utils';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatTabsModule,
    ReactiveFormsModule,
    MomentModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,

    TaskModule,
    MatDividerModule,

    QcModule,
    ExperimentModule,
    AppRoutingModule,
  ],
  providers: [DatePipe, DateService, ApiKeyService, UtilsService],
  bootstrap: [AppComponent],
  exports: [
    RouterModule
  ]
})
export class AppModule {
}
