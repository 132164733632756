<div class="form-container">
    <div class="upload-file-form">
        <input class="hidden" type="file" accept=".csv" (change)="fileBrowseHandler($any($event.target).files)" #fileBrowser/>
        <div class="upload-file-container" drag-drop-file (fileDropped)="onFileDropped($event)">
            <h3>Drag and drop file here</h3>
            <h3>or</h3>
            <div class="browse-file-button" (click)="fileBrowser.click()">Browse for file</div>
        </div>
    </div>

    <div class="form-group">
        <form class="search-form" [formGroup]="uploadQcFileForm" (ngSubmit)="uploadQcFile()">
            <mat-form-field class="form-field" style="width: 200px;">
                <mat-select formControlName="qcType" placeholder="Qc Type">
                    <mat-option *ngFor="let qcType of qcTypeKeys" [value]="qcType">
                        {{ qcType }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="form-field" style="width: 500px;" floatLabel="always">
                <mat-label>Filename</mat-label>
                <input formControlName="filename"
                    matInput
                    placeholder="<camera-id>_<dateId>_<startHour>_<endHour>_<note>.csv"
                    readonly>
            </mat-form-field>

            <br>

            <mat-form-field class="form-field" style="width: 500px;">
                <input type="text" placeholder="Store" aria-label="Store" matInput [formControl]="$any(uploadQcFileForm.get('selectedStoreDisplayName'))" [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let store of filteredStores | async" [value]="store.displayName" (click)="updateStoreQcSections(store)">
                        {{ store.displayName }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="form-field" *ngIf="showQcSection">
                <mat-select formControlName="qcSections" placeholder="Qc Section" multiple>
                    <button mat-button  (click)="toggleAllQcSectionSelection()" [value]="0" class="full-width">Toggle all</button>
                    <mat-option *ngFor="let section of sectionNames" [value]="section.value">
                        {{ section.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <br/>

            <mat-form-field class="form-field">
                <input formControlName="selectedDate" matInput [matDatepicker]="pickerStart" placeholder="Selected Date*">
                <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="form-field">
                <input type="number" matInput placeholder="Start Hour" formControlName="startHour" required>
            </mat-form-field>

            <mat-form-field class="form-field">
                <input type="number" matInput placeholder="End Hour" formControlName="endHour" required>
            </mat-form-field>

            <button class="main-btn" mat-raised-button color="primary" type="submit" [disabled]="isLoading || uploadQcFileForm.pristine || uploadQcFileForm.status != 'VALID'">UPLOAD</button>
        </form>
    </div>

</div>



<div class="error-message">
    {{errorMessage}}
</div>


<button mat-raised-button color="secondary" (click)="goToRecalculate()">Recalculate Accuracy</button>

<div *ngIf="isLoading" class="centered">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<mat-table #table [dataSource]="dataSource" class="table mat-elevation-z8 task-list-table">

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="gotToDetail(element.id)"> {{element.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="storeName">
        <mat-header-cell *matHeaderCellDef> Store </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ storeIdToStoreMap?.get(element.storeId)?.displayName }} ({{ element.storeId }}) </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateId">
        <mat-header-cell *matHeaderCellDef> Date ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.dateId}} ({{element.startHour}} -> {{element.endHour}}) </mat-cell>
    </ng-container>

    <ng-container matColumnDef="fileName">
        <mat-header-cell *matHeaderCellDef> File Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.fileName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="qcType">
        <mat-header-cell *matHeaderCellDef> QC Type </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{qcTypeEnum[element.qcType]}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createTime">
        <mat-header-cell *matHeaderCellDef> Create Time </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createTimeMs | date:'medium'}} </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<div class="footer">
    <mat-paginator [length]="totalItem"
                  [pageSize]="pageSize"
                  [pageSizeOptions]="[100, 500, 1000, 10000]"
                  (page)="onPageChange($event)"
                  [showFirstLastButtons]="true">
    </mat-paginator>
</div>
