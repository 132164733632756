<div class="container">
  <div class="form-wrapper">
    <form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="field-full-width">
        <input matInput placeholder="API Key" formControlName="apikey">
      </mat-form-field>

      <button mat-raised-button class="login-btn" type="submit" [disabled]="(isLoggingIn)" style="margin-top: 10px">
        LOG IN
      </button>
      <div class="error-message">{{errorMessage}}</div>
    </form>
  </div>
</div>
