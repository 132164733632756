import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TaskModule } from '../task/task.module';
import { TaskService } from '../task/task.service';
import { CameraService } from './camera.service';
import { EmailService } from './email.service';
import { MetadataService } from './metadata.service';
import { QcFileDetailComponent } from './qc-file-detail/qc-file-detail.component';
import { QcFileListComponent } from './qc-file-list/qc-file-list.component';
import { QcPlannerComponent } from './qc-planner/qc-planner.component';
import { QcVideoInfoDialogComponent } from './qc-video/qc-video-info-dialog/qc-video-info-dialog.component';
import { QcVideoListComponent } from './qc-video/qc-video-list.component';
import { QcRoutingModule } from './qc.routing.module';
import { QcService } from './qc.service';
import { RecalculateAccuracyComponent } from './recalculate-accuracy/recalculate-accuracy.component';
import { CreateQcPlanDialogComponent } from './qc-planner/create-qc-plan-dialog/create-qc-plan-dialog.component';
import { AccuracyResultDetailDialogComponent } from './qc-file-detail/accuracy-detail-dialog/accuracy-result-detail-dialog.component';
import { PercentPipe } from '@angular/common';
import { DragAndDropFileDirective } from './qc-file-list/drag-drop-file.directive';

@NgModule({
  declarations: [
    QcFileListComponent,
    RecalculateAccuracyComponent,
    QcFileDetailComponent,
    QcPlannerComponent,
    QcVideoListComponent,
    QcVideoInfoDialogComponent,
    CreateQcPlanDialogComponent,
    AccuracyResultDetailDialogComponent,
    DragAndDropFileDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    FormsModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatChipsModule,
    MatTableModule,
    MatRadioModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatTabsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatIconModule,
    MatSnackBarModule,
    MatCardModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTableModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatInputModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    QcRoutingModule,
    NgxJsonViewerModule,
    TaskModule,
  ],
  providers: [
    QcService,
    MetadataService,
    TaskService,
    CameraService,
    EmailService,
    PercentPipe,
  ],
  entryComponents: [
    QcVideoInfoDialogComponent,
    CreateQcPlanDialogComponent,
    AccuracyResultDetailDialogComponent,
  ],
  bootstrap: [],
  exports: [
    QcFileListComponent,
    RecalculateAccuracyComponent,
    QcFileDetailComponent,
    QcVideoListComponent
  ]
})
export class QcModule { }

