import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {ApiKeyService} from "../common/apikey.service";
import {palexy} from "../generated/bundle";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: 'app-login-form',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
  }
)
export class LoginComponent {
  loginForm: FormGroup;
  redirect: string;
  isLoggingIn: boolean = false;
  errorMessage: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private apiKeyService: ApiKeyService
  ) {
    let currentAccount = this.apiKeyService.getCurrentAccount();
    if (currentAccount) {
      this.router.navigate(['/tasks']);
    }
    this.createForm();
  }

  createForm() {
    this.loginForm = this.fb.group({
      apikey: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.redirect = params['redirect'] || '/tasks';
    });
  }

  onSubmit() {
    const formModel = this.loginForm.value;
    this.isLoggingIn = true;

    const request = palexy.janus.v1.GetAccountInfoByTokenRequest.create();
    request.token = formModel.apikey;

    this.apiKeyService.getAccountInfoByToken(request).subscribe(response => {
      this.apiKeyService.onAuthorized(request.token, response.account);
      this.isLoggingIn = false;
      this.snackBar.open('Log in successfully', null, { duration: 2000,  panelClass: ['snackbar-style']});
      window.location.replace(this.redirect);
    }, err => {
      this.errorMessage =  err.error.message;
      this.isLoggingIn = false;
    })
  }
}
