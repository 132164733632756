import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { palexy } from '../../../generated/bundle';
import { TaskService } from '../../task.service';

@Component({
  selector: 'app-update-human-label-link-dialog',
  templateUrl: './update-human-label-link-dialog.component.html',
  styleUrls: ['./update-human-label-link-dialog.component.css']
})
export class UpdateHumanLabelLinkDialogComponent {
  updateHumanLabelLink: FormGroup;
  isSavingInProgress = false;
  taskResultName = '';


  constructor(
    public dialogRef: MatDialogRef<UpdateHumanLabelLinkDialogComponent>,
    private fb: FormBuilder,
    private taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.taskResultName = data.taskResultName;
    this.createForm();
  }

  createForm() {
    this.updateHumanLabelLink = this.fb.group({
      humanLabelLink: ['', Validators.required],
    });
  }

  onSubmit() {
    const formModel = this.updateHumanLabelLink.value;
    const request = palexy.sherlock.v1.UpdateHumanLabelLinkRequest.create();
    request.taskResult = this.taskResultName;
    request.humanLabelLink = formModel.humanLabelLink as string;

    console.log(request);

    this.isSavingInProgress = true;
    this.taskService.updateHumanLabelLink(request).subscribe(_ => {
      this.isSavingInProgress = false;
      this.dialogRef.close({});
      location.reload();
    });
  }
}


