<div>
    <div>
        <a (click)="getDownloadLink(_taskResult.coreTaskResult.debugPersonResult.extractBbResult.allBbDataLink);"
        [style.color]="_taskResult.coreTaskResult.debugPersonResult.extractBbResult.allBbDataLink != ''? 'blue': 'gray'">
      All BB Link
    </a>
    </div>
    <div>
        <a (click)="getDownloadLink(_taskResult.coreTaskResult.debugPersonResult.extractBbResult.trackingDataLink);"
           [style.color]="_taskResult.coreTaskResult.debugPersonResult.extractBbResult.trackingDataLink != ''? 'blue': 'gray'">
      All Tracking Data Link
    </a>
    </div>


    <div>
        <a (click)="getDownloadLink(_taskResult.coreTaskResult.debugPersonResult.extractBbResult.humanLabelLink);"
           [style.color]="_taskResult.coreTaskResult.debugPersonResult.extractBbResult.humanLabelLink != ''? 'blue': 'gray'">
      Human Label Link
    </a>

        <button mat-button (click)="updateHumanLabelLink()">Update Link</button>
    </div>

    <div>
        <a (click)="getDownloadLink(_taskResult.coreTaskResult.debugPersonResult.extractBbResult.reconstructedMergeFileLink);"
           [style.color]="_taskResult.coreTaskResult.debugPersonResult.extractBbResult.reconstructedMergeFileLink != ''? 'blue': 'gray'">
      Merge File Link
    </a>
    </div>
</div>

<mat-checkbox [(ngModel)]="showOtherAction">Show other actions (pickup, interaction)</mat-checkbox>

<div class="person-list">
    <div class="one-person mat-elevation-z2" *ngFor="let item of detailResults">
        <div class="person-header">

            {{item.personId}}, {{item.gender}}, {{item.ageRange}}
            <br /> {{item.startTime | slice:9:11}}:{{item.startTime | slice:11:13}}:{{item.startTime | slice:13:15}} - {{item.endTime | slice:9:11}}:{{item.endTime | slice:11:13}}:{{item.endTime | slice:13:15}}, {{item.durationInSeconds}} seconds, {{item.totalBb}}
            bb
        </div>

        <div class="person-actions">
            <div class="action-item" *ngFor="let entry of item.actionSummaryEntries">
                <div *ngIf="entry.actionType == 'visit' || showOtherAction">
                    <div>{{entry.actionType}}</div>
                    <div class="list-images">
                        <div class="action-image" *ngFor="let imagePath of entry.bbImageLinks">
                            <img src="{{imagePath}}">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
