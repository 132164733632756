<mat-table #table [dataSource]="dataSource" class="mat-elevation-z8 task-list-table">

    <ng-container matColumnDef="displayName">
        <mat-header-cell *matHeaderCellDef> Display Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.displayName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="accuracy">
        <mat-header-cell *matHeaderCellDef> Accuracy </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <div *ngFor="let result of element.taskResults">
                <table>
                    <tr>
                        <th>Predict Data</th>
                        <th>Precision</th>
                        <th>Recall</th>
                        <th>F1 Score</th>
                    </tr>
                    <tr *ngFor="let item of result.coreTaskResult.mergeAccuracyResult.mergeAccuracyResultItems">
                        <!-- <th>{{item.predictedAndLabelItem.predictedBbLink}}</th> -->
                        <th></th>
                        <th>{{item.precision}}</th>
                        <th>{{item.recall}}</th>
                        <th>{{item.f1}}</th>
                        <th>{{item.predictedAndLabelItem.humanLabelBbLink}}</th>
                    </tr>

                    <tr>
                        <th>Average</th>
                        <th>{{result.coreTaskResult.mergeAccuracyResult.avgPrecision}}</th>
                        <th>{{result.coreTaskResult.mergeAccuracyResult.avgRecall}}</th>
                        <th>{{result.coreTaskResult.mergeAccuracyResult.avgF1}}</th>
                    </tr>
                </table>
            </div>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <div class="action-list">
                <div *ngIf="element.taskStatus == 'STATUS_FAILED'">
                    <app-task-retry-btn [task]="element"></app-task-retry-btn>
                </div>
                <app-task-delete-btn [task]="element"></app-task-delete-btn>
            </div>

        </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>