import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map, startWith } from "rxjs/operators";
import { DateService } from '../../common/date';
import { palexy } from '../../generated/bundle';
import { StoreConfigService } from '../../task/storeconfig.service';
import { MetadataService } from '../metadata.service';
import { QcService } from '../qc.service';
import { StoreService } from '../../common/store.service';
import { CameraService } from '../camera.service';

@Component({
  selector: 'app-qc-file-list',
  templateUrl: './qc-file-list.component.html',
  styleUrls: ['./qc-file-list.component.css']
})
export class QcFileListComponent implements OnInit {

  displayedColumns: string[] = ['name', 'storeName', 'dateId', 'fileName', 'qcType', 'createTime'];
  qcFiles: palexy.sherlock.v1.IQcFile[] = [];
  uploadQcFileForm: FormGroup;
  stores: palexy.streaming.v1.IStore[] = [];
  filteredStores: Observable<palexy.streaming.v1.IStore[]>;
  sectionNames: palexy.streaming.v1.IMetadataValue[] = [];
  cameras: palexy.streaming.v1.ICamera[] = [];
  showQcSection: boolean = false;
  storeIdToStoreMap: Map<number, palexy.streaming.v1.IStore>;

  dataSource: MatTableDataSource<palexy.sherlock.v1.IQcFile>;
  storeNameAutoCompleteControl = new FormControl();

  fileBytes: Uint8Array;

  @ViewChild('fileBrowser')
  fileBrowserInput: ElementRef<HTMLInputElement>;

  errorMessage = "";
  isLoading = false;

  qcTypeKeys = Object.keys(palexy.sherlock.v1.QcFile.QcType);
  qcTypeEnum = palexy.sherlock.v1.QcFile.QcType;

  totalItem: number = 0;
  pageSize: number = 100;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private storeService: StoreService,
    private cameraService: CameraService,
    private qcService: QcService,
    private dateService: DateService,
    private metadataService: MetadataService,
  ) {
    this.uploadQcFileForm = this.fb.group({
      selectedStoreDisplayName: ['', Validators.required],
      selectedDate: ['', Validators.required],
      filename: ['', Validators.required],
      qcType: ['ICA_STANDARD_COUNTING', Validators.required],
      startHour: ['', Validators.required],
      endHour: ['', Validators.required],
      qcSections: [],
    });

    this.isLoading = true;

    qcService.fetchQcFiles(0, this.pageSize, true).subscribe((response) => {
      this.qcFiles = response.qcFiles;
      this.dataSource = new MatTableDataSource(this.qcFiles);
      this.totalItem = Number(response.totalElements);
      this.isLoading = false;
    });

    const fetchStores = storeService.fetchStores();
    const fetchCameras = cameraService.listCamera();

    forkJoin([fetchStores, fetchCameras]).subscribe(
      responses => {
        this.stores = responses[0].stores;
        this.filteredStores = this.uploadQcFileForm.get('selectedStoreDisplayName').valueChanges
          .pipe(
            startWith(''),
            map(name => name ? this._filter(name) : this.stores.slice())
          );

        this.storeIdToStoreMap = new Map();
        for (const store of this.stores) {
          this.storeIdToStoreMap.set(store.id, store);
        }

        this.cameras = responses[1];
      }
    )

    this.uploadQcFileForm.get('qcType').valueChanges.subscribe(value => {
      this.showQcSection = this.qcTypeEnum[value as string] == this.qcTypeEnum.ICA_CUSTOMER_JOURNEY;
      let qcSectionControl = this.uploadQcFileForm.get('qcSections');
      if (this.showQcSection) {
        qcSectionControl.setValidators(Validators.required);
      } else {
        qcSectionControl.clearValidators();
      }
      qcSectionControl.updateValueAndValidity();
    });
  }

  ngOnInit() {
  }

  private _filter(value: string): palexy.streaming.v1.IStore[] {
    const filterValue = value.toLowerCase();
    return this.stores.filter(store => store.displayName.toLowerCase().indexOf(filterValue) >= 0);
  }

  private _findStoreByDisplayName(name: string): palexy.streaming.v1.IStore{
    return this.stores.filter(store => store.displayName == name)[0];
  }

  onFileDropped($event) {
    this.processFile($event[0]);
  }


  fileBrowseHandler(files) {
    this.processFile(files[0]);
    this.fileBrowserInput.nativeElement.value = '';
  }

  processFile(file: File) {
    this.errorMessage = "";
    const currentQcType = this.uploadQcFileForm.get('qcType').value;
    this.uploadQcFileForm.reset();
    this.uploadQcFileForm.get('qcType').setValue(currentQcType);

    if (!file.name.endsWith('.csv')) {
      this.errorMessage = "Invalid csv file";
      return;
    }
    this.tryToSetInputFromFilename(file.name);

    let that = this;
    let myReader = new FileReader();
    myReader.onload = function() {
      let arrayBuffer: ArrayBuffer = myReader.result as ArrayBuffer;
      let array = new Uint8Array(arrayBuffer);
      that.fileBytes = array;
    }
    myReader.readAsArrayBuffer(file);
  }

  tryToSetInputFromFilename(filename: string) {
    this.uploadQcFileForm.get('filename').setValue(filename);

    filename = filename.substring(0, filename.length - 4);
    const parts = filename.split('_');

    // store_name
    {
      const cameraId = parts[0];
      const matchedStoreIds = this.cameras.filter(camera => {
        return camera.cameraId.startsWith(cameraId)
            && (camera.cameraId.length - cameraId.length < 6)
            && this.storeIdToStoreMap.has(camera.storeId);
      }).map(camera => camera.storeId);

      const matchedStores = [... new Set(matchedStoreIds)].map(sid => this.storeIdToStoreMap.get(sid));
      if (matchedStores.length == 1) {
        this.uploadQcFileForm.get('selectedStoreDisplayName').setValue(matchedStores[0].displayName);
      }
      if (matchedStores.length > 1) {
        const storeNames = matchedStores.map(s => s.displayName).join(', ');
        this.errorMessage = `There are ${matchedStores.length} matched camera prefix ${cameraId}: ${storeNames}`;
      }
    }

    // date_id
    if (parts.length >= 2 && parts[1].length == 8) {
      try {
        const date = this.dateService.convertDateIdToDate(parts[1]);
        this.uploadQcFileForm.get('selectedDate').setValue(date);
      } catch (e) {
        console.log(e);
      }
    }

    // start_hour
    if (parts.length >= 3 && parts[2].length < 3) {
      try {
        const startHour = Number(parts[2]);
        this.uploadQcFileForm.get('startHour').setValue(startHour);
      } catch (e) {
        console.log(e);
      }
    }

    // end_hour
    if (parts.length >= 4 && parts[3].length < 3) {
      try {
        const endHour = Number(parts[3]);
        this.uploadQcFileForm.get('endHour').setValue(endHour);
      } catch (e) {
        console.log(e);
      }
    }

    this.uploadQcFileForm.markAsDirty();
    this.uploadQcFileForm.markAllAsTouched();
  }

  uploadQcFile() {
    this.errorMessage = "";

    const formModel = this.uploadQcFileForm.value;

    let startHour = formModel.startHour as number;
    let endHour = formModel.endHour as number;

    if (startHour < 0 || startHour > 23) {
      this.errorMessage = 'Start Hour is invalid';
      return;
    }

    if ( endHour < 0 || endHour > 23) {
      this.errorMessage = 'End Hour is invalid';
      return;
    }

    if (endHour < startHour) {
      this.errorMessage = 'End Hour cannot be smaller than Start Hour';
      return;
    }

    this.isLoading = true;

    let request: palexy.sherlock.v1.UploadQcFileRequest = palexy.sherlock.v1.UploadQcFileRequest.create();
    request.content = this.fileBytes;
    let qcFile = palexy.sherlock.v1.QcFile.create();
    qcFile.dateId = this.dateService.convertToPalexyStandardString(formModel.selectedDate).substring(0, 8);
    qcFile.storeId = this._findStoreByDisplayName(formModel.selectedStoreDisplayName).id;
    qcFile.fileName = formModel.filename;
    qcFile.fileType = palexy.sherlock.v1.QcFile.FileType.CSV;
    qcFile.qcType = palexy.sherlock.v1.QcFile.QcType[formModel.qcType as string];
    qcFile.startHour = startHour;
    qcFile.endHour = endHour;

    if (qcFile.qcType == palexy.sherlock.v1.QcFile.QcType.ICA_CUSTOMER_JOURNEY) {
      let qcFileData = palexy.sherlock.v1.QcFileData.create();
      let data = palexy.sherlock.v1.IcaCustomerJourneyData.create();
      data.sections = formModel.qcSections;
      qcFileData.icaCustomerJourneyData = data;
      qcFile.qcFileData = qcFileData;
    }

    request.qcFile = qcFile;
    this.qcService.uploadQcFile(request).subscribe(resp => {
      this.isLoading = false;
      window.location.reload();
    }, err => {
      this.errorMessage = err.error.message;
      this.isLoading = false;
    });
  }

  gotToDetail(qcFileId) {
    this.router.navigate(['qcfiles/' + qcFileId]);
  }

  goToRecalculate() {
    this.router.navigate(['recalculate']);
  }

  onPageChange(event) {
    let pageSize = event.pageSize;
    let pageIndex = event.pageIndex;
    this.isLoading = true;
    this.qcService.fetchQcFiles(pageIndex, pageSize, true).subscribe((response) => {
      this.qcFiles = response.qcFiles;
      this.dataSource = new MatTableDataSource(this.qcFiles);
      this.totalItem = Number(response.totalElements);
      this.isLoading = false;
    });
  }

  toggleAllQcSectionSelection() {
    let isOneItemSelected = this.uploadQcFileForm.value.qcSections != null && (this.uploadQcFileForm.value.qcSections as any[]).length > 0;
    if (isOneItemSelected) {
      this.uploadQcFileForm.controls['qcSections'].patchValue([]);
    } else {
      this.uploadQcFileForm.controls['qcSections'].patchValue(this.sectionNames.map(item => item.value));
    }
  }

  updateStoreQcSections(store) {
    if (this.showQcSection) {
      this.metadataService.listMetadataType(store.companyId, true, true).subscribe((response) => {
        let systemTypeNameEnum = palexy.streaming.v1.SystemTypeName;
        let metadataTypes = response.metadataTypes;
        metadataTypes = metadataTypes.filter(item => item.systemName.toUpperCase() == systemTypeNameEnum[systemTypeNameEnum.SECTION_TESTING_AREA].toUpperCase());
        if (metadataTypes.length == 1) {
          this.sectionNames = metadataTypes[0].metadataValues;
        } else {
          this.sectionNames = [];
        }
        this.uploadQcFileForm.controls['qcSections'].setValue([]);
      })
    }
  }
}


