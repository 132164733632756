<div class="container">
  <div *ngIf="!!currentStore">
    <h2>Store Info</h2>
    <h3>{{ currentStore.displayName }}</h3>
    <ul>
      <li>Store Type : {{ currentStore.storeType | mapVToK: StoreType }}</li>
      <li>Counting Type : {{ currentStore.countingType | mapVToK: CountingType }}</li>
    </ul>
  </div>

  <div>
    <h2>Toolbox</h2>
    <form [formGroup]="copyConfigFromAnotherStoreForm">
      <mat-form-field>
        <input type="text" placeholder="Copy from another Store" matInput formControlName="storeName"
          [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let store of copyConfigFromAnotherStoreFilteredStores | async"
            value="{{store.displayName}}" (click)="selectStoreForCopy(store)">
            {{ store.displayName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>

    <mat-form-field>
      <mat-select placeholder="Copy from another Config Item of this Store">
        <mat-option *ngFor="let item of currentStoreConfigItems" (click)="setStoreConfigItem(item)">
          {{ item.displayName }} ({{ item.appliedDate }})
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div>
      <h3>Suggested Presets</h3>
      <button *ngFor="let preset of currentStoreSuggestedPresets" class="button-margin" mat-raised-button
        color="primary" type="button" matTooltip="{{ preset.note }}" (click)="setConfigPreset(preset)">
        {{ preset.name }}
      </button>
      <span *ngIf="!currentStoreSuggestedPresets">Empty</span>
    </div>

  </div>

  <div>
    <h2>Config</h2>
    <form [formGroup]="storeConfigItemEditForm" (ngSubmit)="onSubmit()">
      <mat-form-field>
        <input matInput placeholder="Display Name" formControlName="displayName" required>
      </mat-form-field>
      <mat-form-field>
        <input type="str" matInput placeholder="Applied date on PRODUCTION from (yyyyMMdd)"
          formControlName="appliedDate">
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit" [disabled]="storeConfigItemEditForm.status != 'VALID'
    || isSavingInProgress">
        {{(isSavingInProgress) ? "SAVING" : "SAVE"}}
      </button>
      <span class="error-message">{{errorMessage}}</span>
    </form>
  </div>
</div>

<app-merging-config-form #mergingConfigForm [disabled]="true"></app-merging-config-form>
