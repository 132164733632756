import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QcFileListComponent } from './qc-file-list/qc-file-list.component';
import { RecalculateAccuracyComponent } from './recalculate-accuracy/recalculate-accuracy.component';
import { QcFileDetailComponent } from './qc-file-detail/qc-file-detail.component';
import { QcPlannerComponent } from './qc-planner/qc-planner.component';
import { QcVideoListComponent } from './qc-video/qc-video-list.component';


const appRoutes: Routes = [
    {
        path: 'qcfiles',
        component: QcFileListComponent
    },
    {
        path: 'recalculate',
        component: RecalculateAccuracyComponent
    },
    {
        path: 'qcfiles/:qcFileId',
        component: QcFileDetailComponent
    },
    {
        path: 'qcplanner',
        component: QcPlannerComponent
    },
    {
        path: 'qcvideos',
        component: QcVideoListComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(
            appRoutes
        )
    ],
    providers: [],
    exports: [
        RouterModule
    ]
})
export class QcRoutingModule { }
