<fieldset *ngIf="!!model && !!defaultMergingConfig" class="container" [disabled]="disabled">

  <ng-container *ngTemplateOutlet="inputTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'singleCameraMaxFeatureDistanceThreshold',
    'label': 'Single camera max feature distance threshold'
  }">
  </ng-container>

  <ng-container *ngTemplateOutlet="inputTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'crossCameraMaxFeatureDistanceThreshold',
    'label': 'Cross camera max feature distance threshold'
  }">
  </ng-container>

  <ng-container *ngTemplateOutlet="inputTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'maxTimeDistanceInSeconds',
    'label': 'Max time distance in seconds'
  }">
  </ng-container>

  <ng-container *ngTemplateOutlet="inputTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'minimumTrackletSize',
    'label': 'Minimum tracklet size'
  }">
  </ng-container>

  <ng-container *ngTemplateOutlet="inputTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'globalViewMaxTimeDistanceInSeconds',
    'label': 'Global view, max time distance in seconds'
  }">
  </ng-container>

  <ng-container *ngTemplateOutlet="inputTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'globalViewMaxFeatureDistanceThreshold',
    'label': 'Global view, max feature distance threshold'
  }">
  </ng-container>

  <ng-container *ngTemplateOutlet="inputTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'maxSameCameraOverlapRatio',
    'label': 'Max same camera overlap ratio'
  }">
  </ng-container>

  <ng-container *ngTemplateOutlet="inputTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'maxCrossCameraOverlapRatio',
    'label': 'Max cross camera overlap ratio'
  }">
  </ng-container>

  <ng-container *ngTemplateOutlet="inputTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'genderCheckingFeatureDistanceThreshold',
    'label': 'Gender checking feature distance threshold'
  }">
  </ng-container>

  <ng-container *ngTemplateOutlet="inputTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'featureDistancePercentileWhenCompareWithGroup',
    'label': 'Feature distance percentile when comparing with a group of tracklets'
  }">
  </ng-container>

  <ng-container *ngTemplateOutlet="checkboxTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'skipRefreshGroupCandidateEveryMerge',
    'label': 'Skip refreshing group candidate every merge'
  }">
  </ng-container>

  <ng-container *ngTemplateOutlet="checkboxTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'skipRunRefine',
    'label': 'Skip run refine'
  }">
  </ng-container>

  <ng-container *ngTemplateOutlet="checkboxTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'skipMergingByNearness',
    'label': 'Skip merging by nearness'
  }">
  </ng-container>

  <ng-container *ngTemplateOutlet="checkboxTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'skipDetectingOutliers',
    'label': 'Skip detecting outlier'
  }">
  </ng-container>

  <ng-container *ngTemplateOutlet="checkboxTpl; context: {
    'model': model,
    'defaultModel': defaultMergingConfig,
    'key': 'skipMainMerging',
    'label': 'Skip main merging'
  }">
  </ng-container>

  <mat-card *ngIf="model.outlierFilterConfig">
    <mat-card-header>
      <mat-card-title>Outlier detection configuration</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.outlierFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig,
        'key': 'minDurationInSeconds',
        'label': 'Minimum duration in seconds after merging'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.outlierFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig,
        'key': 'minBb',
        'label': 'Minimum number of bounding box per tracklet after merging'
      }">
      </ng-container>


      <ng-container *ngTemplateOutlet="checkboxTpl; context: {
        'model': model.outlierFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig,
        'key': 'filterByEntranceCameras',
        'label': 'Filter by entrance camera'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.outlierFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig,
        'key': 'numEntranceCameras',
        'label': 'Number of entrance cameras'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="checkboxTpl; context: {
        'model': model.outlierFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig,
        'key': 'filterBySuspectedStaffs',
        'label': 'Filter by suspected staffs'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.outlierFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig,
        'key': 'suspectedStaffFeatureDistanceThreshold',
        'label': 'Suspected Staff Feature Distance Threshold'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.outlierFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig,
        'key': 'minStaffClusterSize',
        'label': 'Minimum staff cluster size'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="checkboxTpl; context: {
          'model': model.outlierFilterConfig,
          'defaultModel': defaultMergingConfig.outlierFilterConfig,
          'key': 'prefilterByDetectStaticObjects',
          'label': 'Prefilter by detect static object'
        }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.outlierFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig,
        'key': 'prefilterByDetectStaticObjectsMinNumBoundingBox',
        'label': 'Prefilter by detect static object, min number of bounding box'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="checkboxTpl; context: {
        'model': model.outlierFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig,
        'key': 'skipRemoveShortStayWhenMerge',
        'label': 'Skip remove short stay when merge'
      }"></ng-container>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Static object configuration</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.outlierFilterConfig.staticObjectFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig.staticObjectFilterConfig,
        'key': 'minDurationInStoreInSecond',
        'label': 'Min duration of static object'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="checkboxTpl; context: {
          'model': model.outlierFilterConfig,
          'defaultModel': defaultMergingConfig.outlierFilterConfig,
          'key': 'filterByEntranceMetadataAppearances',
          'label': 'Filter by entrance metadata appearances'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.outlierFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig,
        'key': 'maxDurationInSeconds',
        'label': 'Max duration in seconds'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.outlierFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig,
        'key': 'minStaffClusterDurationInSeconds',
        'label': 'Min staff cluster duration in seconds'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="checkboxTpl; context: {
          'model': model.outlierFilterConfig,
          'defaultModel': defaultMergingConfig.outlierFilterConfig,
          'key': 'removePeopleAtStaffSection',
          'label': 'Remove people at staff section'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="checkboxTpl; context: {
          'model': model.outlierFilterConfig,
          'defaultModel': defaultMergingConfig.outlierFilterConfig,
          'key': 'skipStdDerivedCameraDistanceToFilter',
          'label': 'Skip Std Derived Camera Distance To Filter'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="checkboxTpl; context: {
          'model': model.outlierFilterConfig,
          'defaultModel': defaultMergingConfig.outlierFilterConfig,
          'key': 'skipRemoveClustersWithLowAvgValidBbsProb',
          'label': 'Skip Remove cluster with low average invalid bb probability'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="checkboxTpl; context: {
            'model': model.outlierFilterConfig,
            'defaultModel': defaultMergingConfig.outlierFilterConfig,
            'key': 'keepClusterWithConfidentBbProb',
            'label': 'Keep cluster with some high confident bb prob'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="checkboxTpl; context: {
          'model': model.outlierFilterConfig,
          'defaultModel': defaultMergingConfig.outlierFilterConfig,
          'key': 'skipFilterByNumSecondsPerBb',
          'label': 'Skip filter by num seconds per bb'
        }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.outlierFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig,
        'key': 'maxClusterCamerasToRemovePeopleAtStaffSections',
        'label': 'Max cluster cameras to remove people at staff sections'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.outlierFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig,
        'key': 'maxItemsAtStaffSections',
        'label': 'Max items at staff sections'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model,
        'defaultModel': defaultMergingConfig,
        'key': 'veryCloseFeatureDistanceThreshold',
        'label': 'Very Close Feature Distance Threshold'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.outlierFilterConfig,
        'defaultModel': defaultMergingConfig.outlierFilterConfig,
        'key': 'maxGapToAddDuration',
        'label': 'Max gap to add duration'
      }">
      </ng-container>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Mall merging configuration</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.runMallMergingConfig,
        'defaultModel': defaultMergingConfig.runMallMergingConfig,
        'key': 'thresholdMergePersonInOut',
        'label': 'Threshold merge person in out'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.runMallMergingConfig,
        'defaultModel': defaultMergingConfig.runMallMergingConfig,
        'key': 'thresholdDistanceForCompanion',
        'label': 'Threshold distance for companion'
      }">
      </ng-container>

      <div>Mall merging run merge local very close</div>

      <ng-container *ngTemplateOutlet="checkboxTpl; context: {
        'model': model.runMallMergingConfig,
        'defaultModel': defaultMergingConfig.runMallMergingConfig,
        'key': 'runMergeLocalVeryClose',
        'label': 'Run merge local very close'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.runMallMergingConfig,
        'defaultModel': defaultMergingConfig.runMallMergingConfig,
        'key': 'localVeryCloseFeatureDistance',
        'label': 'Threshold very close feature distance'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.runMallMergingConfig,
        'defaultModel': defaultMergingConfig.runMallMergingConfig,
        'key': 'localVeryCloseIndexDistance',
        'label': 'Threshold very close index distance'
      }">
      </ng-container>

    </mat-card-content>

  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Minimal Camera Counting Configuration</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.minimalCameraCountingConfig,
        'defaultModel': defaultMergingConfig.minimalCameraCountingConfig,
        'key': 'remergeInAndOutFeatureDistanceThreshold',
        'label': 'Threshold feature distance for remerge in and out tracklets'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.minimalCameraCountingConfig,
        'defaultModel': defaultMergingConfig.minimalCameraCountingConfig,
        'key': 'remergeVeryCloseFeatureDistanceThreshold',
        'label': 'Threshold feature distance for remerge very close tracklets'
      }">
      </ng-container>

      <ng-container *ngTemplateOutlet="inputTpl; context: {
        'model': model.minimalCameraCountingConfig,
        'defaultModel': defaultMergingConfig.minimalCameraCountingConfig,
        'key': 'remergeMaxTimeDistanceSeconds',
        'label': 'Max time distance in seconds for remerge'
      }">
      </ng-container>
    </mat-card-content>
  </mat-card>
</fieldset>

<ng-template #inputTpl let-model="model" let-defaultModel="defaultModel" let-key="key" let-label="label">
  <mat-form-field>
    <mat-label>{{label}}</mat-label>
    <input type="number" [class.red]="model[key] !== defaultModel[key]" matInput [(ngModel)]="model[key]" required>
  </mat-form-field>
</ng-template>

<ng-template #checkboxTpl let-model="model" let-defaultModel="defaultModel" let-key="key" let-label="label">
  <mat-checkbox [class.red]="model[key] !== defaultModel[key]" [(ngModel)]="model[key]">
    {{label}}
  </mat-checkbox>
</ng-template>
