import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { palexy } from "../generated/bundle";

@Injectable({
  providedIn: "root",
})
export class StoreService {
  constructor(private http: HttpClient) {}

  fetchStores = (): Observable<palexy.streaming.v1.ListStoresResponse> =>
    this.http
      .get(`api/v1/stores`)
      .pipe(map(palexy.streaming.v1.ListStoresResponse.fromObject));

  fetchCompanyStores = (
    companyId: number
  ): Observable<palexy.streaming.v1.ListStoresResponse> =>
    this.http
      .get(`api/v1/stores?companyId=${companyId}`)
      .pipe(map(palexy.streaming.v1.ListStoresResponse.fromObject));

  getStore = (storeId): Observable<palexy.streaming.v1.Store> =>
    this.http
      .get(`api/v1/stores/${storeId}`)
      .pipe(map(palexy.streaming.v1.Store.fromObject));
}
