<div class="container">
  <form [formGroup]="prepareQcVideoForm" (ngSubmit)="submit()">

    <div class="form-container">
      <mat-form-field class="store-name">
        <input type="text" placeholder="Store" aria-label="Store" matInput formControlName="selectedStoreDisplayName" [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let store of filteredStores | async" [value]="store.displayName" (click)="updateStoreCameras(store)">
            {{ store.displayName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-checkbox formControlName="isFilterCameras">Filter Cameras</mat-checkbox>

      <mat-form-field class="store-name">
        <mat-select formControlName="cameraIds" multiple>
          <mat-option *ngFor="let camera of storeCameras" [value]="camera.cameraId">
            {{ camera.cameraId }}
          </mat-option>
  
        </mat-select>
      </mat-form-field>

      <mat-checkbox formControlName="sendMail">Send Notification</mat-checkbox>

    </div>

    <ng-container *ngIf="prepareQcVideoForm.value.sendMail">
      <br>
      <mat-form-field class="full-width">
        <mat-label>Notification emails</mat-label>
        <mat-chip-list #chipList aria-label="Notification emails">
          <mat-chip *ngFor="let email of qcEmails" [selectable]="false"
               [removable]="true" (removed)="removeEmail(email)">
          {{email}}
          <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input placeholder="Add email..."
             [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="true"
             (matChipInputTokenEnd)="addEmail($event)">
        </mat-chip-list>
        </mat-form-field>
      <br/>
    </ng-container>

    <div class="form-container">
      <mat-form-field>
        <input formControlName="selectedDate" matInput [matDatepicker]="pickerStart" placeholder="Selected Date*">
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
      </mat-form-field>
  
      <mat-form-field>
        <input type="number" matInput placeholder="Start Hour (0-23, Inclusive)" formControlName="startHour" required>
      </mat-form-field>
  
      <mat-form-field>
        <input type="number" matInput placeholder="End Hour (0-23, Inclusive)" formControlName="endHour" required>
      </mat-form-field>
  
      <mat-form-field>
        <mat-select formControlName="storageProvider" placeholder="Storage Provider">
          <mat-option *ngFor="let storageProvider of storageProviderKeys" [value]="storageProvider">
            {{ storageProvider }}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field>
        <mat-select formControlName="anonymizeFace" placeholder="Anonymize Face" required>
          <mat-option [value]="false">False</mat-option>
          <mat-option [value]="true">True</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field *ngIf="prepareQcVideoForm.value.anonymizeFace">
        <input type="number" min="1" matInput placeholder="FPS" formControlName="fps">
      </mat-form-field>

      <mat-form-field>
        <mat-select formControlName="createQcPlan" placeholder="Create Qc Plan" required>
          <mat-option [value]="false">False</mat-option>
          <mat-option [value]="true">True</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button color="primary" type="submit"
        [disabled]="isLoading || prepareQcVideoForm.pristine || prepareQcVideoForm.status != 'VALID' ">
        Create
      </button>
    </div>
  </form>

  <div class="error-message">
    {{errorMessage}}
  </div>

  <div *ngIf="isLoading" class="centered">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>

  <table class="table" *ngIf="!isLoading">
    <thead>
      <tr class="table-header">
        <th> Id </th>
        <th> Store Name </th>
        <th> Date </th>
        <th> Hour </th>
        <th> Anonymize Face </th>
        <th> Storage Provider </th>
        <th> Status </th>
        <th> Create Time </th>
        <th> Actions </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of prepareQcVideosTasks" class="table-row">
        <td> {{ item.id }} </td>
        <td (click)="openQcVideoInfoDialog(item)"> {{ storeIdMap.get(+item.taskAdvanceInfo.prepareQcVideosAdvanceInfo.storeId).displayName }}</td>
        <td> {{ item.taskAdvanceInfo.prepareQcVideosAdvanceInfo.dateId }} </td>
        <td> {{ item.taskAdvanceInfo.prepareQcVideosAdvanceInfo.startHour }} - {{ item.taskAdvanceInfo.prepareQcVideosAdvanceInfo.endHour }} </td>
        <td>
          {{ item.taskAdvanceInfo.prepareQcVideosAdvanceInfo.anonymizeFace ? 'True' : 'False' }} 
          {{ item.taskAdvanceInfo.prepareQcVideosAdvanceInfo.anonymizeFace ? '(fps: ' + item.taskAdvanceInfo.prepareQcVideosAdvanceInfo.fps + ')' : ''}}
        </td>
        <td> {{ item.taskAdvanceInfo.prepareQcVideosAdvanceInfo.storageProvider }} </td>
        <td [ngClass]="{
          'task-done': item.taskStatus == 'STATUS_DONE',
          'task-failed': item.taskStatus == 'STATUS_FAILED'
        }"> {{ item.taskStatus }} </td>
        <td> {{ item.createTime | date:'medium' }} </td>
        <td>
          <app-task-delete-btn [task]="item" *ngIf="item.taskStatus != 'STATUS_CREATED'"></app-task-delete-btn>
        </td>
      </tr>
    </tbody>
  </table>

</div>


