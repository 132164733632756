
<form class="edit-form" [formGroup]="storeConfigEditForm" (ngSubmit)="onSubmit()">
    <mat-form-field class="field-full-width">
        <input type="text" placeholder="Store" aria-label="Store" matInput formControlName="storeName" [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let store of filteredStores | async" value="{{store.displayName}}" (click)="selectStore(store)">
                {{ store.displayName }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <button class="main-btn" mat-raised-button color="primary" type="submit" [disabled]="storeConfigEditForm.pristine 
      || storeConfigEditForm.status != 'VALID'
      || isSavingInProgress"> {{(isSavingInProgress) ? "SAVING" : "SAVE"}}</button>

    <span class="error-message">{{errorMessage}}</span>
</form>