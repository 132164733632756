import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { palexy } from '../../generated/bundle';
import { ExperimentService } from '../experiment.service';

@Component({
  selector: 'app-test-set-detail',
  templateUrl: './test-set-detail.component.html',
  styleUrls: ['./test-set-detail.component.css']
})
export class TestSetDetailComponent  {
  displayedColumns: string[] = ['id', 'description', 'storeId', 'dateId', 'numVideos', 'createTime', 'actions'];
  testCases: palexy.sherlock.v1.IExperimentTestCase[] = [];
  addTestCaseForm: FormGroup;

  dataSource: MatTableDataSource<palexy.sherlock.v1.ITestSet>;
  errorMessage = "";
  isLoading = false;
  testSetId: number;

  constructor(
    private experimentService: ExperimentService, 
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.addTestCaseForm = this.fb.group({
      testCaseId: ['', Validators.required]
    });

    this.isLoading = true;

    this.route.paramMap.subscribe((params: ParamMap) => {
      const testSetId = params.get('id');
      this.testSetId = +testSetId;
      this.experimentService.listTestCasesInTestSet(testSetId).subscribe((response) => {
        this.isLoading = false;
        this.testCases = response.testCases;
        this.dataSource = new MatTableDataSource(this.testCases);
      });
    });
    
  }

  addTestCase() {
    this.errorMessage = "";

    const formModel = this.addTestCaseForm.value;
    this.isLoading = true;

    let request = palexy.sherlock.v1.AddTestCaseToTestSetRequest.create();
    
    request.testSet = 'testsets/' + this.testSetId;
    request.testCaseId = formModel.testCaseId;

    this.experimentService.addTestCaseToTestSet(request).subscribe(resp => {
      this.isLoading = false;
      window.location.reload();
    }, err => {
      this.errorMessage = err.error.message;
      this.isLoading = false;
    })
  }

  removeTestCase(testCaseId) {
    if(confirm("Are you sure you wan to remove this test case?")) {
      let request = palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest.create();
      
      request.testSet = 'testsets/' + this.testSetId;
      request.testCaseId = testCaseId;

      this.experimentService.removeTestCaseFromTestSet(request).subscribe(resp => {
        this.isLoading = false;
        window.location.reload();
      }, err => {
        this.errorMessage = err.error.message;
        this.isLoading = false;
      })
    }
  }
}


