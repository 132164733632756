<form class="edit-form" [formGroup]="taskEditForm" (ngSubmit)="onSubmit()">
    <mat-form-field>
        <input matInput placeholder="Display Name" formControlName="displayName" required>
    </mat-form-field>

    <mat-form-field>
        <mat-select placeholder="Task Type" formControlName="taskType">
            <mat-option *ngFor="let taskType of taskTypes" value="{{taskType.value}}">
                {{ taskType.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div
      *ngIf="taskEditForm.get('taskType').value === 'TYPE_DEBUG_PERSON'"
      formGroupName="debugPerson"
    >
        <mat-form-field>
            <input matInput placeholder="debugPersonStoreId" formControlName="storeId" required>
        </mat-form-field>
        <div class="flex-container">
            <mat-form-field class="left-flex-field">
                <input formControlName="selectedDate" matInput [matDatepicker]="pickerStart"
                    placeholder="Selected Date" required>
                <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="left-flex-field">
                <mat-select placeholder="Start Hour" formControlName="startHour" required>
                    <mat-option *ngFor="let hour of hours" value="{{hour}}">
                        {{ hour }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="left-flex-field">
                <mat-select placeholder="End Hour" formControlName="endHour" required>
                    <mat-option *ngFor="let hour of hours" value="{{hour}}">
                        {{ hour }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div
      *ngIf="['TYPE_EXPORT_HAKUHODO', 'TYPE_HAKUHODO_JOIN_PARTIAL_MERGING_RESULTS', 'TYPE_HAKUHODO_EXTRAPOLATE_DEMOGRAPHY', 'TYPE_HAKUHODO_EDIT_TRAFFIC'].includes(taskEditForm.get('taskType').value)"
      formGroupName="hakuhodo"
    >
      <mat-form-field>
        <mat-label>Start Date Id (inclusive)</mat-label>
        <input matInput placeholder="20231228" formControlName="startDateIdInclusive" required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>End Date Id (inclusive)</mat-label>
        <input matInput placeholder="20231231" formControlName="endDateIdInclusive" required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Store Id</mat-label>
        <input matInput formControlName="storeId" required>
      </mat-form-field>
    </div>

    <div
      *ngIf="taskEditForm.get('taskType').value === 'TYPE_EXPORT_HAKUHODO'"
      formGroupName="hakuhodoExportTask"
    >
      <mat-checkbox formControlName="keepSectionVisitOnly">Export section data only</mat-checkbox>
      <mat-form-field>
        <mat-label>Directional Pass By Store Id</mat-label>
        <input matInput formControlName="directionalPassByStoreId" required>
      </mat-form-field>
    </div>


      <div
        *ngIf="taskEditForm.get('taskType').value === 'TYPE_HAKUHODO_EDIT_TRAFFIC'"
        formGroupName="hakuhodoEditTrafficTask"
      >
        <mat-form-field class="field-full-width">
          <textarea matInput placeholder="Comment" formControlName="rawText" rows="5"></textarea>
          <mat-hint>
            Input multiple lines, each line begins with the type (visit or pass_by),
            then store id, date id, and value for editing traffic
            or store id, date id, hour, and value for editing pass by,
            separated by tab(s) or space(s).
          </mat-hint>
        </mat-form-field>
        <table *ngIf="!!taskEditForm.get('hakuhodoEditTrafficTask').get('rawText').value && !errorMessage">
          <thead>
          <tr>
            <th>Type</th>
            <th>Store ID</th>
            <th>Date ID</th>
            <th>Hour</th>
            <th>Target Value</th>
          </thead>
          <tbody>
          <tr *ngFor="let row of taskEditForm.get('hakuhodoEditTrafficTask').get('edits').value">
            <td>{{row.field}}</td>
            <td>{{row.storeId}}</td>
            <td>{{row.dateId}}</td>
            <td>{{row.hour}}</td>
            <td>{{row.value}}</td>
          </tbody>
        </table>
      </div>

    <div
      *ngIf="taskEditForm.get('taskType').value === 'TYPE_EXPORT_OSOOL_EVENTS'"
      formGroupName="exportOsool"
    >
      <mat-form-field>
        <mat-label>Start Date Id (inclusive)</mat-label>
        <input matInput placeholder="20231228" formControlName="startDateIdInclusive" required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>End Date Id (inclusive)</mat-label>
        <input matInput placeholder="20231231" formControlName="endDateIdInclusive" required>
      </mat-form-field>
    </div>

    <button class="main-btn" mat-raised-button color="primary" type="submit"
      [disabled]="taskEditForm.pristine || !taskEditForm.valid|| isSavingInProgress || !!errorMessage"
    >
      {{(isSavingInProgress) ? "SAVING" : "SAVE"}}
    </button>

  <span class="error-message">{{errorMessage}}</span>
</form>
