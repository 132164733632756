import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject, ReplaySubject } from "rxjs";

@Injectable()
export class UtilsService {

  constructor(
    private snackBar: MatSnackBar,
  ) {
  }

  filter(fullList: any[], filteredSubject: ReplaySubject<any[]> | BehaviorSubject<any[]>,
         filterControl: FormControl, keyFn: (any) => string) {
    if (!fullList || fullList.length == 0) return;

    let search = filterControl.value as string;
    if (!search) {
      filteredSubject.next(fullList.slice());
      return;
    }

    search = search.trim().toLowerCase();
    filteredSubject.next(
      fullList.filter(ele => keyFn(ele).toLowerCase().indexOf(search) >= 0)
    );
  }

  showMessage(message: string, durationInMillis = 2000) {
    this.snackBar.open(message, null, {duration: durationInMillis, panelClass: ['snackbar-style']});
  }
}
