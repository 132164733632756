<div *ngIf="isLoading" class="centered">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>



<div *ngIf="qcFile">
    <div>
        Name: {{ qcFile.name }}
    </div>
    <div>
        File Name: {{qcFile.fileName}}
    </div>

    <div>
        Store ID: {{qcFile.storeId}}
    </div>

    <div *ngIf="store">
        Store Name: {{store.displayName}}
    </div>

    <div>
        Date ID: {{qcFile.dateId}}
    </div>

    <div>
        <button mat-raised-button color="warn" (click)="deleteQcFile()">DELETE</button>
    </div>

    <table class="table" *ngIf="!isLoading">
        <thead>
          <tr class="table-header">
            <th> Date ID </th>
            <th> Accuracy Type </th>
            <th *ngIf="qcFile.qcType == qcType.ICA_CUSTOMER_JOURNEY"> Section </th>
            <th> Hour Slot </th>
            <th *ngIf="qcFile.qcType == qcType.ICA_ENTRANCE_COUNTING"> Camera </th>
            <th> Accuracy Number </th>
            <th> System Result </th>
            <th> Qc Result Min </th>
            <th> Qc Result Max </th>
            <th> Detail </th>
            <th> Create Time </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of accuracyResults" class="table-row" [ngClass]="{
            'low-accuracy': item.accuracyNumber < 0.8
          }">
            <td> {{ item.dateId }} </td>
            <td> {{ accuracyTypeEnum[item.accuracyType] }} </td>
            <td *ngIf="qcFile.qcType == qcType.ICA_CUSTOMER_JOURNEY"> {{ item.sectionName }} </td>
            <td> {{ !HOURLY_ACCURACY_TYPES.includes(item.accuracyType) && item.hourSlot == 0 ? '' : item.hourSlot }} </td>
            <td *ngIf="qcFile.qcType == qcType.ICA_ENTRANCE_COUNTING"> {{ item.cameraId }} </td>
            <td> {{ item.accuracyNumber | percent:"1.0-2" }} </td>
            <td> {{ item.systemResult | number:"1.0-4" }} </td>
            <td> {{ item.qcResultMin | number:"1.0-4" }} </td>
            <td> {{ item.qcResultMax | number:"1.0-4" }} </td>
            <td (click)="openAccuracyResultDetailDialog(item)"> {{ item.additionalData }} </td>
            <td> {{ item.createTimeMs | date:'medium' }} </td>
          </tr>
        </tbody>
      </table>

</div>