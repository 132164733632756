import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, ActivationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { palexy } from '../generated/bundle';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MetadataService {
  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }

  listMetadataType = (companyId: number, isSystem: boolean, includeMetadataValues: boolean): Observable<palexy.streaming.v1.ListMetadataTypeResponse> =>
    this.http
      .get(`/api/v1/metadatatype?company_id=${companyId}&is_system=${isSystem}&include_metadata_values=${includeMetadataValues}`)
      .pipe(
        map(resp => palexy.streaming.v1.ListMetadataTypeResponse.fromObject(resp))
      );

}
