import { palexy } from "../generated/bundle";

export class Task {
    id: number;
    name: string;
    displayName: string;
    taskType: string;
    taskStatus: string;
    createTime: Date;
    endProcessingTime: Date;
    taskAdvanceInfo: any;
    taskResults: palexy.sherlock.v1.SherlockTaskResult[];
}

export class CreateTaskRequest {
    constructor(public sherlock_task: Task) {}
}

export class ListTasksResponse {
    sherlockTasks: Task[];
}
  

export const TaskFromServer = (record: any): Task => ({
    id: record.id,
    name: record.name,
    displayName: record.displayName,
    taskType: record.taskType,
    taskStatus: record.taskStatus,
    createTime: new Date(record.createTime),
    endProcessingTime: record.endProcessingTime ? new Date(record.endProcessingTime): null,
    taskAdvanceInfo: record.taskAdvanceInfo,
    taskResults: record.taskResults ? record.taskResults.map(result => palexy.sherlock.v1.SherlockTaskResult.fromObject(result)) : []
})

export const ListTasksResponseFromServer = (record: any): ListTasksResponse => ({
    sherlockTasks: record.sherlockTasks ? record.sherlockTasks.map((task: any) => TaskFromServer(task)) : []
})
