<div class="button-div">
    <a mat-raised-button color="primary" href="configs/new">CREATE STORE CONFIG</a>
    <a mat-raised-button color="primary" href="merging_config_presets">STORE CONFIG PRESETS</a>
    <button mat-raised-button color="primary" (click)="showRerunMergingView()">RERUN MERGING</button>
</div>


<mat-table #table [dataSource]="dataSource" class="mat-elevation-z8 task-list-table">
    <ng-container matColumnDef="storeName">
        <mat-header-cell *matHeaderCellDef> Store Name </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <a href={{element.name}}> {{element.storeData ? element.storeData.displayName : ''}} </a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="configName">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createTime">
        <mat-header-cell *matHeaderCellDef> Create Time </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createTime | date: 'short'}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
