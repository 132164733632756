import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import * as moment from "moment";

@Injectable()
export class DateService {
  constructor(public datePipe: DatePipe) {
  }

  convertToPalexyStandardString = (inputDate: Date): string => {
    return this.datePipe.transform(inputDate, "yyyyMMdd'T'HHmmss");
  };

  convertDateToDateId = (inputDate: Date): string => {
    return this.datePipe.transform(inputDate, "yyyyMMdd");
  };

  convertDateIdToDate = (inputDateId: string): Date => {
    return moment(inputDateId, "YYYYMMDD").toDate();
  };

  getDateWithHour = (inputDate: Date, hour: number): Date => {
    let newDate = new Date(inputDate);
    newDate.setHours(hour, 0, 0, 0);
    return newDate;
  };

  generateDateIdList(startDate: Date, endDate: Date): string[] {
    const dateIds = [];
    for (const currDate = new Date(startDate); currDate <= endDate; currDate.setDate(currDate.getDate() + 1)) {
      dateIds.push(this.convertDateToDateId(currDate));
    }
    return dateIds;
  }
}