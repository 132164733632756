import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipList } from '@angular/material/chips';
import { MatSnackBar, } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DateService } from '../../common/date';
import { palexy } from '../../generated/bundle';
import { QcService } from '../qc.service';
import { StoreService } from '../../common/store.service';

@Component({
  selector: 'app-recalculate-accuracy',
  templateUrl: './recalculate-accuracy.component.html',
  styleUrls: ['./recalculate-accuracy.component.css']
})
export class RecalculateAccuracyComponent implements OnInit {

  form: FormGroup;
  isLoading = false;
  isSavingInProgress = false;
  errorMessage = '';
  stores: palexy.streaming.v1.IStore[];
  filteredStores: Observable<palexy.streaming.v1.IStore[]>;
  storeControl = new FormControl();

  @ViewChild('chipList') chipList: MatChipList;
  @ViewChild('storeInput') storeInput: ElementRef;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    storeService: StoreService,
    private qcService: QcService,
    private snackBar: MatSnackBar,
    private dateService: DateService,
  ) {
    this.createForm();
    this.isLoading = true;

    storeService.fetchStores().subscribe((response) => {
      this.stores = response.stores;
      this.filteredStores = this.storeControl.valueChanges.pipe(
        startWith(''),
        map(name => this._filter(name))
      );
      this.isLoading = false;
    }, err => {
      this.errorMessage = err.error.message;
      this.isLoading = false;
    });
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.storeDisplayNamesFormArray.statusChanges.subscribe(
      status => this.chipList.errorState = status === 'INVALID'
    );
  }

  createForm() {
    this.form = this.fb.group({
      storeDisplayNames: this.fb.array([], this.validateArrayNotEmpty),
      startDate: ['', Validators.required],
      endDate: ['', Validators.required]
    });
  }


  private _filter(value: string): palexy.streaming.v1.IStore[] {
    let filteredStores = this.stores.slice();
    if (value) {
      value = value.toLowerCase();
      filteredStores = filteredStores.filter(store => store.displayName.toLowerCase().includes(value));
    }
    const selectedStores = this.form.value.storeDisplayNames;
    filteredStores = filteredStores.filter(store => !(selectedStores as string[]).includes(store.displayName));
    return filteredStores;
  }

  private _findStoresByDisplayName(names: string[]): palexy.streaming.v1.IStore[] {
    return this.stores.filter(store => names.includes(store.displayName));
  }


  get storeDisplayNamesFormArray(): FormArray {
    return this.form.controls['storeDisplayNames'] as FormArray;
  }

  validateArrayNotEmpty(c: FormControl) {
    if (!c.value || c.value.length === 0) {
      return {
        validateArrayNotEmpty: {valid: false}
      };
    }
    return null;
  }

  removeSelectedStore(index: number) {
    this.storeDisplayNamesFormArray.removeAt(index);
    this.form.markAsDirty();
  }

  onStoreSelected(event: MatAutocompleteSelectedEvent) {
    this.storeDisplayNamesFormArray.push(this.fb.control(event.option.value));
    this.storeControl.setValue(null);
    this.storeInput.nativeElement.value = '';
    this.form.markAsDirty();
  }

  showStoreOptions() {
    if (this.storeInput.nativeElement.ariaExpanded != true) {
      this.storeInput.nativeElement.blur();
      setTimeout(() => this.storeInput.nativeElement.focus(), 0);
    }
  }

  onSubmit() {
    this.errorMessage = "";
    this.isLoading = true;

    const formModel = this.form.value;
    const storeDisplayNames = formModel.storeDisplayNames as string[];
    const storeIds = this._findStoresByDisplayName(storeDisplayNames).map(store => store.id as number);
    const startDateId = this.dateService.convertDateToDateId(formModel.startDate);
    const endDateId = this.dateService.convertDateToDateId(formModel.endDate);

    let request: palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest = palexy.sherlock.v1.BatchCalculateAndSaveAccuracyRequest.create();

    request.storeIds = storeIds;
    request.startDate = startDateId;
    request.endDate = endDateId;
    this.qcService.recalculateAccuracy(request).subscribe(resp => {
      this.isLoading = false;
      this.snackBar.open('Recalculate succesfully!', null, { duration: 3000,  panelClass: ['snackbar-style']});
      let that = this;
      setTimeout(function() {
        that.router.navigate(['qcfiles']);
      }, 500);

    }, err => {
      this.errorMessage = err.error.message;
      this.isLoading = false;
    });
  }
}


