<form class="edit-form" [formGroup]="updateToProductionDataForm" (ngSubmit)="onSubmit()">
    <div class="section section-title mat-title">Updating Experiment Config To PRODUCTION Level</div>

    <div class="flex-container">
        <mat-form-field class="field-full-width">
            <input type="str" matInput placeholder="Applied date (yyyyMMdd)" formControlName="selectedDate" required>
        </mat-form-field>
    </div>

    <button class="main-btn" mat-raised-button color="primary" type="submit" [disabled]="updateToProductionDataForm.pristine 
          || updateToProductionDataForm.status != 'VALID'"> SAVE </button>
    <span class="error-message">{{errorMessage}}</span>


    <div class="section section-title mat-title">{{storeConfigItem.displayName}}</div>
    <ngx-json-viewer [json]="storeConfigItem.configData.mergingConfig"></ngx-json-viewer>
</form>