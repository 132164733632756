<div *ngIf="isLoading" class="centered">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>


<div class="form-group">

    <form class="creation-form" [formGroup]="createExperimentForm" (ngSubmit)="createExperiment()">
        <mat-form-field class="field-full-width">
            <input matInput placeholder="Programming Name  [a-z]([a-z0-9]+-)+[a-z0-9]+" formControlName="programmingName" required>
        </mat-form-field>
        <br/>

        <mat-form-field class="field-full-width description-wrapper">
            <textarea class="description" matInput placeholder="Description" formControlName="description"></textarea>
        </mat-form-field>
        <br/>

        <mat-form-field class="field-full-width">
            <input matInput placeholder="Video Processor Version" formControlName="videoProcessorVersion">
        </mat-form-field>
        <br/>

        <mat-form-field class="field-full-width">
            <input matInput placeholder="Tracking Processor Version" formControlName="trackingProcessorVersion">
        </mat-form-field>
        <br/>

        <mat-form-field class="field-full-width">
            <input matInput placeholder="Merging Processor Version" formControlName="mergingProcessorVersion">
        </mat-form-field>
        <br/>

        <mat-form-field class="field-full-width">
            <mat-select formControlName="selectedCacheId" placeholder="Cache">
                <mat-option *ngFor="let cache of caches" [value]="cache.id">
                    {{ cache.displayName }} - {{ cache.cacheYoloVersion }} - {{ cache.cacheReidVersion }} - {{ cache.cacheAgeGroupGenderVersion }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br/>

        <mat-form-field class="field-full-width">
            <mat-select formControlName="testSetId" placeholder="Test Set">
                <mat-option *ngFor="let testSet of testSets" [value]="testSet.id">
                    {{ testSet.displayName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br/>

        <button class="main-btn" mat-raised-button color="primary" type="submit" [disabled]="isLoading || createExperimentForm.pristine || createExperimentForm.status != 'VALID'">CREATE EXPERIMENT</button>
    </form>
</div>
<div class="error-message">
    {{errorMessage}}
</div>