import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { google, palexy } from '../../generated/bundle';
import { StoreConfigService } from '../storeconfig.service';

@Component({
  selector: 'app-update-to-production-dialog',
  templateUrl: './update-to-production-dialog.component.html',
  styleUrls: ['./update-to-production-dialog.component.css']
})
export class UpdateToProductionDialogComponent {

  updateToProductionDataForm: FormGroup;
  isSavingInProgress = false;
  storeConfigItem: palexy.streaming.v1.StoreConfigItem;
  errorMessage = '';

  constructor(
    public dialogRef: MatDialogRef<UpdateToProductionDialogComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storeConfigService: StoreConfigService
  ) {
    this.storeConfigItem = data;
    this.createForm();
  }

  createForm() {
    this.updateToProductionDataForm = this.fb.group({
      selectedDate: ['', Validators.required ],
    });
  }

  onSubmit() {
    const formModel = this.updateToProductionDataForm.value;
    let request = palexy.streaming.v1.UpdateStoreConfigItemRequest.create();
    let storeConfigItem = palexy.streaming.v1.StoreConfigItem.create();
    storeConfigItem.name = this.storeConfigItem.name;
    storeConfigItem.appliedDate = formModel.selectedDate as string;

    request.storeConfigItem = storeConfigItem;
    request.updateMask = google.protobuf.FieldMask.create();
    request.updateMask.paths = ['applied_scope', 'applied_date'];

    this.isSavingInProgress = true;

    this.errorMessage = '';
    this.storeConfigService.updateStoreConfigItem(request).subscribe(_ => {
      this.snackBar.open('Config item updated successfully', null, { duration: 2000});
      this.isSavingInProgress = false;
      location.reload();
    }, err => {
      this.isSavingInProgress = false;
      this.errorMessage = err.error.message;
    });
  }

}


