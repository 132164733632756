import {palexy} from "../generated/bundle";

export class StoreConfig {
    id: string;
    name: string;
    storeData: Store;
    storeId: number;
    createTime: Date;
}

class Store {
    id: number;
    name: string;
    displayName: string;
}

export class ListStoreConfigsResponse {
    storeConfigs: StoreConfig[];
}

export class StoreConfigItem {
  id: number;
  name: string;
  displayName: string;
  configType: string;
  storeConfigId: number;
  configData: palexy.streaming.v1.ConfigData;
  fullData: { mergingConfig: object };
  shortData: { mergingConfig: object };
  showData: { mergingConfig: object };
  appliedScope: string;
  appliedDate: string;
}

export class ListStoreConfigItemsResponse {
    storeConfigItem: StoreConfigItem[]
}

export const StoreConfigFromServer = (record: any): StoreConfig => ({
    id: record.id,
    name: record.name,
    storeData: StoreFromServer(record.storeData),
    storeId: record.storeId,
    createTime: new Date(record.createTime),
})

const StoreFromServer = (record: any): Store => ({
    id: record.id,
    name: record.name,
    displayName: record.displayName
})

export const ListStoreConfigsResponseFromServer = (record: any): ListStoreConfigsResponse => ({
    storeConfigs: record.storeConfigs ? record.storeConfigs.map((storeConfig: any) => StoreConfigFromServer(storeConfig)) : []
})

export const StoreConfigItemFromServer = (record: any): StoreConfigItem => {
  let configData = palexy.streaming.v1.ConfigData.fromObject(record.configData);
  return <StoreConfigItem>{
    id: record.id,
    name: record.name,
    displayName: record.displayName,
    configType: record.configType,
    storeConfigId: record.storeConfigId,
    configData: configData,
    fullData: palexy.streaming.v1.ConfigData.toObject(configData, {defaults: true}),
    shortData: {},
    showData: {},
    appliedDate: record.appliedDate,
    appliedScope: record.appliedScope
  }
}

export const ListStoreConfigItemsResponseFromServer = (record: any): ListStoreConfigItemsResponse => ({
    storeConfigItem: record.storeConfigItem ? record.storeConfigItem.map((storeConfigItem: any) => StoreConfigItemFromServer(storeConfigItem)) : []
})
