

import { Input, OnInit, Component } from '@angular/core';
import { palexy } from '../../../generated/bundle';
import { MatDialog } from '@angular/material/dialog';
import { UpdateHumanLabelLinkDialogComponent } from './update-human-label-link-dialog.component';
import { Task } from '../../task.model';
import {TaskService} from "../../task.service";

@Component({
  selector: 'app-debug-person-result',
  templateUrl: './debug-person-result.component.html',
  styleUrls: ['./debug-person-result.component.css']
})
export class DebugPersonResultComponent implements OnInit {

  _taskResult: palexy.sherlock.v1.ISherlockTaskResult;

  @Input() task: Task;

  detailResults: palexy.sherlock.v1.IDetailResult[];

  showOtherAction = false;

  @Input()
  set taskResult(result: palexy.sherlock.v1.ISherlockTaskResult) {
    this.detailResults = result.coreTaskResult.debugPersonResult.extractBbResult.detailResults.sort(
      (r1, r2) => +r1.startTime.substring(9, 15) - +r2.startTime.substring(9, 15))
    this._taskResult = result
  }

  dataSource;

  constructor(
    private dialog: MatDialog,
    private taskService: TaskService
  ) {}

  ngOnInit() {


  }

  updateHumanLabelLink() {
    const dialogRef = this.dialog.open(UpdateHumanLabelLinkDialogComponent, {
      height: '400px',
      width: '600px',
      data: {
        taskResultName: this.task.name + '/results/' + this._taskResult.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getDownloadLink(link: String) {
    if (link == ''){
      alert('File does not exist!');
      return;
    }

    this.taskService.generateSignedUrl(link).subscribe(resp => {
      window.open(resp.url);
    }, err => {
      alert('Cannot download this file!');
    })
  }
}


