<form [formGroup]="mergingConfigPresetForm" (ngSubmit)="onSubmit()">
  <mat-form-field>
    <input matInput placeholder="Name" formControlName="name" required>
  </mat-form-field>

  <mat-form-field>
    <mat-select multiple placeholder="Company" formControlName="companyIds">
      <mat-option *ngFor="let c of companies" [value]="c.id" multi>
        {{ c.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-select multiple placeholder="Store Types" formControlName="storeTypes">
      <mat-option *ngFor="let t of (StoreType| keyvalue)" [value]="t.value">
        {{ t.key }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-select multiple placeholder="Counting Types" formControlName="countingTypes">
      <mat-option *ngFor="let t of (CountingType| keyvalue)" [value]="t.value">
        {{ t.key }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <textarea matInput placeholder="Note" formControlName="note"></textarea>
  </mat-form-field>

  <mat-checkbox formControlName="isEnabledForSuggestion">
    Use in Suggestion
  </mat-checkbox>

  <button mat-raised-button color="primary"
    [disabled]="mergingConfigPresetForm.invalid || mergingConfigPresetForm.pristine">
    {{isNew ? 'Create' : 'Save'}}
  </button>


</form>
<app-merging-config-form #mergingConfigForm [disabled]="!isNew"></app-merging-config-form>
