import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { palexy } from '../../../generated/bundle';
import { TaskService } from '../../../task/task.service';

@Component({
  selector: 'accuracy-result-detail-dialog',
  templateUrl: './accuracy-result-detail-dialog.component.html',
  styleUrls: ['./accuracy-result-detail-dialog.component.css']
})
export class AccuracyResultDetailDialogComponent {

  accuracyResult: palexy.sherlock.v1.AccuracyResult;
  details: string[];

  constructor(
    public dialogRef: MatDialogRef<AccuracyResultDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: palexy.sherlock.v1.AccuracyResult,
    private router: Router,
    private taskService: TaskService,
    private snackBar: MatSnackBar,
  ) {
    this.accuracyResult = data;
    this.details = this.accuracyResult.additionalData.split("|");
  }
}


