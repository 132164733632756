import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateService } from '../../common/date';
import { palexy } from '../../generated/bundle';
import { TaskService } from '../task.service';

@Component({
  selector: 'app-create-debug-data-dialog',
  templateUrl: './create-debug-data-dialog.component.html',
  styleUrls: ['./create-debug-data-dialog.component.css']
})
export class CreateDebugDataDialogComponent {
  createDebugDataForm: FormGroup;
  isSavingInProgress = false;

  hours = Array.from(Array(24).keys());
  maxHours = Array.from(Array(16).keys());

  storeId: number;
  storeConfigId: number;

  constructor(
    public dialogRef: MatDialogRef<CreateDebugDataDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dateService: DateService,
    private taskService: TaskService) {
    this.storeId = data.storeId;
    this.storeConfigId = data.storeConfigId;
    this.createForm();
  }

  createForm() {
    this.createDebugDataForm = this.fb.group({
      selectedDate: ['', Validators.required ],
      startHour: [10, Validators.required],
      endHour: [21, Validators.required],
      maxHoursToSplit: [3, Validators.required],
      includeFfmpegResults: [false, Validators.required],
      generateBbLevelDetail: [false, Validators.required],
    });
  }

  onSubmit() {
    const formModel = this.createDebugDataForm.value;
    const request = palexy.sherlock.v1.CreateDebugTaskForStoreConfigRequest.create();
    request.startHour = formModel.startHour as number;
    request.endHour = formModel.endHour as number;
    request.maxHoursToSplit = formModel.maxHoursToSplit as number;
    request.includeFfmpegResults = formModel.includeFfmpegResults as boolean;
    request.storeId = +this.storeId ;
    request.storeConfigId = +this.storeConfigId;
    request.dateId = this.dateService.convertToPalexyStandardString(formModel.selectedDate).substring(0, 8);
    request.generateBbLevelDetail = formModel.generateBbLevelDetail as boolean;

    this.isSavingInProgress = true;
    this.taskService.createDebugTaskForStoreConfig(request).subscribe(_ => {
      this.isSavingInProgress = false;
      this.dialogRef.close({});
      location.reload();
    });
  }
}


