import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipList } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from "rxjs";
import { map, startWith } from 'rxjs/operators';
import { palexy } from '../../generated/bundle';
import { StoreConfigService } from "../storeconfig.service";
import { StoreService } from '../../common/store.service';

@Component({
  selector: 'app-rerun-merging-dialog',
  templateUrl: './rerun-merging-dialog.component.html',
  styleUrls: ['./rerun-merging-dialog.component.css']
})
export class RerunMergingDialogComponent implements OnInit, AfterViewInit {

  rerunMergingForm: FormGroup;
  isLoading = false;
  isSavingInProgress = false;
  errorMessage = '';
  stores: palexy.streaming.v1.IStore[];
  filteredStores: Observable<palexy.streaming.v1.IStore[]>;
  storeControl = new FormControl();

  @ViewChild('chipList') chipList: MatChipList;
  @ViewChild('storeInput') storeInput: ElementRef;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private storeService: StoreService,
    private storeConfigService: StoreConfigService,
    public dialogRef: MatDialogRef<RerunMergingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    if (data.store) {
      this.stores = [data.store];
      this.createForm(this.stores);
      this.filteredStores = this.storeControl.valueChanges.pipe(
        startWith(''),
        map(name => this._filter(name))
      );
    } else {
      this.createForm([]);
      this.isLoading = true;
      this.storeService.fetchStores().subscribe(response => {
        this.stores = response.stores;
        this.filteredStores = this.storeControl.valueChanges.pipe(
          startWith(''),
          map(name => this._filter(name))
        );
        this.isLoading = false;
      }, err => {
        this.errorMessage = err.error.message;
        this.isLoading = false;
      });
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.storeDisplayNamesFormArray.statusChanges.subscribe(
      status => this.chipList.errorState = status === 'INVALID'
    );
  }

  private _filter(value: string): palexy.streaming.v1.IStore[] {
    let filteredStores = this.stores.slice();
    if (value) {
      value = value.toLowerCase();
      filteredStores = filteredStores.filter(store => store.displayName.toLowerCase().includes(value));
    }
    const selectedStores = this.rerunMergingForm.value.storeDisplayNames;
    filteredStores = filteredStores.filter(store => !(selectedStores as string[]).includes(store.displayName));
    return filteredStores;
  }

  private _findStoresByDisplayName(names: string[]): palexy.streaming.v1.IStore[] {
    return this.stores.filter(store => names.includes(store.displayName));
  }

  createForm(stores: palexy.streaming.v1.IStore[]) {
    this.rerunMergingForm = this.fb.group({
      storeDisplayNames: this.fb.array(stores.map(store => store.displayName), this.validateArrayNotEmpty),
      dateIds: ['', [Validators.required, this.validateDateIds]],
    });
  }

  get storeDisplayNamesFormArray(): FormArray {
    return this.rerunMergingForm.controls['storeDisplayNames'] as FormArray;
  }

  validateArrayNotEmpty(c: FormControl) {
    if (!c.value || c.value.length === 0) {
      return {
        validateArrayNotEmpty: {valid: false}
      };
    }
    return null;
  }

  validateDateIds(c: FormControl) {
    if (!(c.value as string).match(/^[0-9]{8}( *- *[0-9]{8})?(, *[0-9]{8}( *- *[0-9]{8})?)*$/)) {
      return {
        invalidFormat: {valid: false}
      };
    }
    return null;
  }

  parseDateIds(dateIdsStr: string): { startDate: string, endDate: string }[] {
    const results = [];
    const values = dateIdsStr.split(',').map(e => e.trim());
    for (let value of values) {
      let dateIds = value.split('-').map(e => e.trim());
      let startDateId = dateIds[0];
      let endDateId = dateIds.length > 1 ? dateIds[1] : startDateId;
      results.push({startDate: startDateId, endDate: endDateId});
    }
    return results;
  }

  onSubmit() {
    const formModel = this.rerunMergingForm.value;
    const storeDisplayNames = formModel.storeDisplayNames as string[];
    const storeIds = this._findStoresByDisplayName(storeDisplayNames).map(store => store.id as number);
    const dateRanges = this.parseDateIds(formModel.dateIds as string);

    const storeNamesStr = storeDisplayNames.join('\n + ');
    const dateRangesStr = dateRanges.map(dr => `${dr.startDate} - ${dr.endDate}`).join('\n + ');
    const confirmMessage = `Are you sure to rerun merging with below information?` +
        `\nStores:\n + ${storeNamesStr}` +
        `\nDate ranges:\n + ${dateRangesStr}`;

    if (!confirm(confirmMessage)) return;

    this.errorMessage = '';
    this.isSavingInProgress = true;
    this.storeConfigService.rerunMerging(storeIds, dateRanges).subscribe((resp) => {
      if (resp.hasOwnProperty('affectedRowsCount') && resp.affectedRowsCount > 0) {
        this.snackBar.open('Submit rerun merging task successfully', null, {duration: 2000});
        this.isSavingInProgress = false;
        this.dialogRef.close();
      } else {
        this.isSavingInProgress = false;
        this.errorMessage = 'No task created. Please ensure all tracking files has not been purged or archived';
      }
    }, err => {
      this.isSavingInProgress = false;
      this.errorMessage = err.error.message;
    });
  }

  removeSelectedStore(index: number) {
    this.storeDisplayNamesFormArray.removeAt(index);
    this.rerunMergingForm.markAsDirty();
  }

  onStoreSelected(event: MatAutocompleteSelectedEvent) {
    this.storeDisplayNamesFormArray.push(this.fb.control(event.option.value));
    this.storeControl.setValue(null);
    this.storeInput.nativeElement.value = '';
    this.rerunMergingForm.markAsDirty();
  }

  showStoreOptions() {
    if (this.storeInput.nativeElement.ariaExpanded != true) {
      this.storeInput.nativeElement.blur();
      setTimeout(() => this.storeInput.nativeElement.focus(), 0);
    }
  }
}


