import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExperimentListComponent } from './experiment-list/experiment-list.component';
import { TestCaseListComponent } from './test-case-list/test-case-list.component';
import { TestSetListComponent } from './test-set-list/test-set-list.component';
import { ExperimentCreateComponent } from './experiment-create/experiment-create.component';
import { TestSetDetailComponent } from './test-set-detail/test-set-detail.component';
import { CacheListComponent } from './cache-list/cache-list.component';


const appRoutes: Routes = [
    {
        path: 'experiments',
        component: ExperimentListComponent
    },
    {
        path: 'experiments/create',
        component: ExperimentCreateComponent
    },
    {
        path: 'testcases',
        component: TestCaseListComponent
    },
    {
        path: 'testsets',
        component: TestSetListComponent
    },
    {
        path: 'testsets/:id',
        component: TestSetDetailComponent
    },
    {
        path: 'caches',
        component: CacheListComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(
            appRoutes
        )
    ],
    providers: [],
    exports: [
        RouterModule
    ]
})
export class ExperimentRoutingModule { }
