<div *ngIf="task">
  <h1>{{task.displayName}}</h1>
  {{task.taskType}} - {{task.taskStatus}}
  <ngx-json-viewer [json]="task.taskAdvanceInfo" [expanded]="false"></ngx-json-viewer>


  <div *ngIf="task.taskType =='TYPE_DEBUG_PERSON' ">
    <div *ngFor="let result of taskResults">
      <app-debug-person-result [taskResult]="result" [task]="task"></app-debug-person-result>
    </div>
  </div>

  <div *ngIf="task.taskType == 'TYPE_EXECUTE_POST_MERGE_CONFIG' ">
    <div *ngFor="let result of taskResults">
      <app-post-merge-result [taskResult]="result" [task]="task"></app-post-merge-result>
    </div>
  </div>

  <div *ngIf="task.taskType == 'TYPE_EXPORT_OSOOL_EVENTS' ">
    <div *ngFor="let result of taskResults">
      <ngx-json-viewer [json]="result.coreTaskResult.exportOsoolEventsResult"></ngx-json-viewer>
    </div>
  </div>
</div>
