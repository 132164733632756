<div class="example-container mat-elevation-z8">
    <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="configId">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Config ID </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngStyle]="getStyle(element)"> {{element.id}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="displayName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Display Name </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngStyle]="getStyle(element)"> {{element.displayName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="appliedScope">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Applied Scope </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngStyle]="getStyle(element)"> {{element.appliedScope}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="appliedDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Applied Date </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngStyle]="getStyle(element)"> {{element.appliedDate}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Actions </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngStyle]="getStyle(element)">
                <div class="action-list">
                    <app-apply-config-btn [config]="element"></app-apply-config-btn>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"matRipple class="element-row" [cdkDetailRow]="row"
            [cdkDetailRowTpl]="tpl">
        </mat-row>

    </mat-table>
</div>

<ng-template #tpl let-element>
    <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
        <ngx-json-viewer [json]="element.showData"></ngx-json-viewer>
        <button class="main-btn" mat-raised-button color="primary" (click)="toggleFullConfig(element)"> Show/Hide Full config </button>
    </div>
</ng-template>
