import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { palexy } from "../generated/bundle";
import {
  ListStoreConfigItemsResponse,
  ListStoreConfigItemsResponseFromServer,
  ListStoreConfigsResponse,
  ListStoreConfigsResponseFromServer,
  StoreConfig,
  StoreConfigFromServer,
  StoreConfigItem,
  StoreConfigItemFromServer,
} from "./storeconfig.model";

@Injectable()
export class StoreConfigService {
  constructor(private http: HttpClient) {}

  fetchStoreConfigs = (): Observable<ListStoreConfigsResponse> =>
    this.http
      .get(`api/v1/configs`)
      .pipe(map((resp) => ListStoreConfigsResponseFromServer(resp)));

  createStoreConfig = (newConfig: StoreConfig): Observable<StoreConfig> =>
    this.http
      .post(`api/v1/configs`, {
        storeConfig: newConfig,
      })
      .pipe(map((resp) => StoreConfigFromServer(resp)));

  getStoreConfig = (name: string): Observable<StoreConfig> =>
    this.http
      .get(`api/v1/${name}`)
      .pipe(map((resp) => StoreConfigFromServer(resp)));

  fetchConfigItems = (
    configName: string
  ): Observable<ListStoreConfigItemsResponse> =>
    this.http
      .get(`api/v1/${configName}/items`)
      .pipe(map((resp) => ListStoreConfigItemsResponseFromServer(resp)));

  createStoreConfigItem = (
    newConfigItem: StoreConfigItem,
    configName: string
  ): Observable<StoreConfigItem> =>
    this.http
      .post(`api/v1/${configName}/items`, {
        storeConfigItem: newConfigItem,
      })
      .pipe(map((resp) => StoreConfigItemFromServer(resp)));

  updateStoreConfigItem = (
    request: palexy.streaming.v1.UpdateStoreConfigItemRequest
  ): Observable<StoreConfigItem> => {
    return this.http
      .patch(`api/v1/${request.storeConfigItem.name}`, request.toJSON())
      .pipe(map((resp) => StoreConfigItemFromServer(resp)));
  };

  deleteStoreConfigItem = (name: string): Observable<any> =>
    this.http.delete(`api/v1/${name}`).pipe(map((resp) => resp));

  rerunMerging = (
    storeIds: number[],
    dateRanges: { startDate: string; endDate: string }[]
  ): Observable<any> =>
    this.http
      .post(`api/v1/stores:rerunMerging`, {
        storeIds: storeIds,
        dateRanges: dateRanges,
      })
      .pipe(map((resp) => resp));

  createMergingConfigPreset = (
    mergingConfigPreset: palexy.streaming.v1.MergingConfigPreset
  ) =>
    this.http
      .post("/api/v1/merging_config_presets", { mergingConfigPreset })
      .pipe(map(palexy.streaming.v1.MergingConfigPreset.fromObject));

  listMergingConfigPreset = (
    storeId: number = undefined,
    isForSuggestion: boolean = false,
  ) => {
    let params = new HttpParams().set("is_for_suggestion", isForSuggestion.toString());
    if (!!storeId) {
      params = params.set("store_id.value", storeId.toString())
    }
    return this.http
      .get("/api/v1/merging_config_presets", { params })
      .pipe(
        map(palexy.streaming.v1.ListMergingConfigPresetsResponse.fromObject)
      );
  };

  getMergingConfigPreset = (mergingConfigPresetId: number) =>
    this.http
      .get(`/api/v1/merging_config_presets/${mergingConfigPresetId}`)
      .pipe(map(palexy.streaming.v1.MergingConfigPreset.fromObject));

  updateMergingConfigPreset = (
    request: palexy.streaming.v1.UpdateMergingConfigPresetRequest
  ) =>
    this.http
      .put(
        `/api/v1/merging_config_presets/${request.mergingConfigPreset.id}`,
        request.toJSON()
      )
      .pipe(map(palexy.streaming.v1.MergingConfigPreset.fromObject));

  deleteMergingConfigPreset = (mergingConfigPresetId: number) =>
    this.http.delete(`/api/v1/merging_config_presets/${mergingConfigPresetId}`);

  getDefaultMergingConfig = () =>
    this.getMergingConfigPreset(1).pipe(map(p => p.config));
    // TODO add a "is_default" field to preset instead
}
