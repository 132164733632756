<mat-toolbar *ngIf="doneAuthenticating && !router.url.startsWith('/login') && account !== undefined"
    color="primary" class="mat-elevation-z4" style="height: 76px; min-height: 60px;">
    <img style="width: 40px;
  height: 40px;
  background: white;
  padding: 18px;
  margin-left: -16px;" src="https://storage.googleapis.com/palexy-static-files/palexy_logo_small.png">

    <div style="display: flex; flex-direction:column; margin-left: 30px; height: 100%; justify-content: space-around">
        Sherlock
    </div>

    <div class="flex-spacer"></div>

    <nav mat-tab-nav-bar>
        <a class="button" mat-tab-link [routerLink]="['tasks']">TASKS</a>
        <a class="button" mat-tab-link [routerLink]="['configs']">CONFIGS</a>
        <a class="button" mat-tab-link [routerLink]="['qcfiles']">QC DATA</a>
        <a class="button" mat-tab-link [routerLink]="['experiments']">EXPERIMENTS</a>
        <a class="button" mat-tab-link [routerLink]="['qcplanner']">QC PLANNER</a>
        <a class="button" mat-tab-link [routerLink]="['qcvideos']">QC VIDEOS</a>
    </nav>

    <div class="flex-spacer"></div>

    <div *ngIf="account">
      <span class='account-name'> {{account.fullName ? account.fullName : account.username}} </span>
      <button mat-icon-button [matMenuTriggerFor]="accountMenu"><mat-icon>arrow_drop_down</mat-icon></button>
      <mat-menu #accountMenu="matMenu">
        <button mat-menu-item (click)="logout()"><mat-icon>power_settings_new</mat-icon>Log out</button>
      </mat-menu>
    </div>
</mat-toolbar>

<mat-divider></mat-divider>
<router-outlet *ngIf="doneAuthenticating"></router-outlet>
