import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { palexy } from '../../generated/bundle';
import { ExperimentService } from '../experiment.service';

@Component({
  selector: 'app-cache-list',
  templateUrl: './cache-list.component.html',
  styleUrls: ['./cache-list.component.css']
})
export class CacheListComponent  {
  displayedColumns: string[] = ['displayName', 'id', 'yoloVersion', 'reidVersion', 'ageGroupGenderVersion', 'createTime'];
  caches: palexy.sherlock.v1.IProcessingResultCache[] = [];
  createCacheForm: FormGroup;

  dataSource: MatTableDataSource<palexy.sherlock.v1.IProcessingResultCache>;
  errorMessage = "";
  isLoading = false;

  constructor(
    private experimentService: ExperimentService, 
    private router: Router,
    private fb: FormBuilder,
  ) {
    this.createCacheForm = this.fb.group({
      displayName: ['', Validators.required],
      yoloVersion: ['', Validators.required],
      reidVersion: ['', Validators.required],
      ageGroupGenderVersion: ['', Validators.required],
    });

    this.isLoading = true;

    this.experimentService.listCaches().subscribe((response) => {
      this.isLoading = false;
      this.caches = response.caches;
      this.dataSource = new MatTableDataSource(this.caches);
    });
  }

  createCache() {
    this.errorMessage = "";

    const formModel = this.createCacheForm.value;
    this.isLoading = true;

    let request = palexy.sherlock.v1.CreateCacheRequest.create();
    let cache = palexy.sherlock.v1.ProcessingResultCache.create();

    cache.displayName = formModel.displayName as string;
    cache.cacheYoloVersion = formModel.yoloVersion as string;
    cache.cacheReidVersion = formModel.reidVersion as string;
    cache.cacheAgeGroupGenderVersion = formModel.ageGroupGenderVersion as string;

    request.cache = cache;

    this.experimentService.createCache(request).subscribe(resp => {
      this.isLoading = false;
      window.location.reload();
    }, err => {
      this.errorMessage = err.error.message;
      this.isLoading = false;
    })
  }
}


