import { StoreConfigItem } from '../storeconfig.model';
import { MatTableDataSource } from "@angular/material/table";
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { StoreConfigService } from '../storeconfig.service';
import { palexy } from '../../generated/bundle';


@Component({
  selector: 'app-config-list',
  templateUrl: './config-list.component.html',
  styleUrls: ['./config-list.component.css'],
  animations: [
    trigger('detailExpand', [
        state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
        state('*', style({ height: '*', visibility: 'visible' })),
        transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
],
})
export class ConfigListComponent {

  displayedColumns: string[] = ['configId', 'displayName', 'appliedScope', 'appliedDate', 'actions'];
  taskDisplayedColumns: string[] = ['configData']
  isExpansionDetailRow = (index, row) => row.hasOwnProperty('configData');
  click = false;

  dataSource;

  _configItems: StoreConfigItem[] = [];

  @Input()
  set configItems(configItems: StoreConfigItem[]) {
    this._configItems = configItems.sort((item1, item2) => {
      if (item1.appliedScope != item2.appliedScope) {
        if (item1.appliedScope == 'PRODUCTION') {
          return -1;
        }
        return 1;
      }
      if (item1.appliedScope != 'PRODUCTION') {
        return 0;
      }
      if (item1.appliedDate > item2.appliedDate) {
        return -1;
      }
      if (item1.appliedDate < item2.appliedDate) {
        return 1;
      }
      return 0;
    });
    this.createShortData(this._configItems);
    this.dataSource = new MatTableDataSource(this._configItems);
  }

  constructor(
    private storeConfigService: StoreConfigService,
  ) {}

  getStyle(element) {
    if (element.appliedScope == 'PRODUCTION')
      return {'color':'red'};
    return {'color': 'black'}
  }

  toggleFullConfig(element) {
    if (element.showData == element.fullData) {
      element.showData = element.shortData;
    } else {
      element.showData = element.fullData
    }
  }

  async createShortData(configItems: StoreConfigItem[]) {
    const defaultConfig = palexy.streaming.v1.MergingConfig.toObject(
      palexy.streaming.v1.MergingConfig.create(
        (await this.storeConfigService.getDefaultMergingConfig().toPromise())
      ),
      {defaults: true}
    );
    for (let ind in configItems) {
      let itemData = palexy.streaming.v1.MergingConfig.toObject(
        palexy.streaming.v1.MergingConfig.create(this.getItemMergingConfig(configItems[ind].configData)),
        {defaults: true}
      )
      itemData = removeDuplicateProperties(itemData, defaultConfig)
      configItems[ind].shortData.mergingConfig = itemData;
      configItems[ind].showData = configItems[ind].shortData;
    }
  }

  private getItemMergingConfig(configData: palexy.streaming.v1.ConfigData) {
    if (configData.configData === 'mergingConfig') {
      return configData.mergingConfig;
    } else {
      return configData.mergingConfigPreset.config;
    }
  }
}

function removeDuplicateProperties(obj1: object, obj2: object): object {
  for (let prop in obj1) {
    if (obj1.hasOwnProperty(prop) && obj2.hasOwnProperty(prop)) {
      if (typeof obj1[prop] === 'object' && obj1[prop] !== null && typeof obj2[prop] === 'object' && obj2[prop] !== null) {
        removeDuplicateProperties(obj1[prop], obj2[prop]);
        if (Object.keys(obj1[prop]).length === 0) {
          delete obj1[prop];
        }
      } else if (obj1[prop] === obj2[prop]) {
        delete obj1[prop];
      }
    }
  }
  return obj1;
}

