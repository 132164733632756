import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { DateService } from '../../common/date';
import { UtilsService } from '../../common/utils';
import { palexy } from '../../generated/bundle';
import { QcService } from '../qc.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateQcPlanDialogComponent } from './create-qc-plan-dialog/create-qc-plan-dialog.component';
import { CompanyService } from '../../common/company.service';
import { StoreService } from '../../common/store.service';

@Component({
  selector: 'app-qc-planner',
  templateUrl: './qc-planner.component.html',
  styleUrls: ['./qc-planner.component.css']
})
export class QcPlannerComponent {
  configDataSource: MatTableDataSource<palexy.sherlock.v1.IQcPeriodConfig>;
  configDisplayedColumns: string[] = ['configId', 'startingDateId', 'numDaysInPeriod', 'percentageOfStore', 'numQcDaysPerStore', 'creator', 'createTime', 'lastUpdateTimeMs', 'plannedUntilDateId', 'planDetailLastGenerateTimeMs', 'actions'];

  companyControl: FormControl;
  companyFilterControl: FormControl;

  storeControl: FormControl;
  storeFilterControl: FormControl;

  createPeriodConfigForm: FormGroup;

  qcItems: palexy.sherlock.v1.IQcPlanItem[] = [];
  storeNameMap: Map<number, string> = new Map();

  isLoading = false;
  companies: palexy.streaming.v1.ICompany[] = [];
  stores: palexy.streaming.v1.IStore[] = [];
  companyStores: palexy.streaming.v1.IStore[] = [];
  filteredCompanies: BehaviorSubject<palexy.streaming.v1.ICompany[]> = new BehaviorSubject<palexy.streaming.v1.ICompany[]>([]);
  filteredStores: BehaviorSubject<palexy.streaming.v1.IStore[]> = new BehaviorSubject<palexy.streaming.v1.IStore[]>([]);
  errorMessage = "";
  periodConfigErrorMessage = "";
  showConfigArea = false;

  private _onDestroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private qcService: QcService,
    private dateService: DateService,
    private utilsService: UtilsService,
    private storeService: StoreService,
    private companyService: CompanyService,
  ) {
    this.createForm();
  }

  private createForm() {
    this.companyControl = this.fb.control(null, Validators.required);
    this.storeControl = this.fb.control(null);

    this.companyFilterControl = this.fb.control('');
    this.companyFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.utilsService.filter(this.companies, this.filteredCompanies, this.companyFilterControl, c => c.name);
      });

    this.storeFilterControl = this.fb.control('');
    this.storeFilterControl.valueChanges
      .pipe(takeUntil(this.companyControl.valueChanges))
      .subscribe(() => {
        this.utilsService.filter(this.stores, this.filteredStores, this.storeFilterControl, s => s.displayName);
      });

    this.createPeriodConfigForm = this.fb.group({
      startingDate: ['', Validators.required],
      numDaysInPeriod: ['', Validators.required],
      percentageOfStores: ['', Validators.required],
      numQcDaysPerStore: ['', Validators.required],
      creator: ['', Validators.required]
    });

    this.isLoading = true;
    const fetchCompanies = this.companyService.getCompanies();
    const fetchStores = this.storeService.fetchStores();

    forkJoin([fetchCompanies, fetchStores]).subscribe(
      results => {
        this.isLoading = false;
        this.companies = results[0].companies;
        this.stores = results[1].stores;
        this.storeNameMap.clear();
        for (const store of this.stores) {
          this.storeNameMap.set(store.id, store.displayName);
        }
        this.filteredCompanies.next(this.companies.slice());
        this.filteredStores.next(this.stores.slice());
      }
    );
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  selectCompany(company: palexy.streaming.v1.ICompany) {
    const companyId = company.id;
    this.companyStores = this.stores.filter(s => s.companyId == companyId);;

    this.filteredStores.next(this.companyStores);
    this.storeControl.setValue(null, {emitEvent: false});

    this.storeFilterControl.valueChanges.pipe(
      takeUntil(this.companyControl.valueChanges)
    ).subscribe(() => this.utilsService.filter(this.companyStores, this.filteredStores, this.storeFilterControl, s => s.displayName));

    this.isLoading = true;
    const fetchQcPlanItems = this.qcService.getPlanItems(companyId);
    const fetchQcPeriodConfigs = this.qcService.getPeriodConfigs(companyId);
    forkJoin([fetchQcPlanItems, fetchQcPeriodConfigs]).subscribe(
      results => {
        this.qcItems = results[0].items;
        this.configDataSource = new MatTableDataSource(results[1].qcPeriodConfigs);
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.utilsService.showMessage(error?.error?.message ?? error.message);
      }
    )
  }

  selectStore(store: palexy.streaming.v1.IStore) {
    this.isLoading = true;
    this.qcService.getPlanItems(store.companyId, store.id).subscribe(resp => {
      this.qcItems = resp.items;
      this.isLoading = false;
    });
  }

  createPeriodConfig() {
    if(confirm("Are you sure you wan to create this config?")) {
      const formModel = this.createPeriodConfigForm.value;
      let request: palexy.sherlock.v1.CreateQcPeriodConfigRequest = palexy.sherlock.v1.CreateQcPeriodConfigRequest.create();
      request.periodConfig = palexy.sherlock.v1.QcPeriodConfig.create();

      const company = this.companyControl.value as palexy.streaming.v1.ICompany;
      const companyId = company.id as number;
      const dateId = this.dateService.convertToPalexyStandardString(formModel.startingDate).substring(0, 8);

      request.periodConfig.companyId = companyId;
      request.periodConfig.startingDateId = dateId;
      request.periodConfig.numDaysInPeriod = formModel.numDaysInPeriod as number;
      request.periodConfig.percentageOfStore = formModel.percentageOfStores as number;
      request.periodConfig.numQcDaysPerStore = formModel.numQcDaysPerStore as number;
      request.periodConfig.creator = formModel.creator as string;

      this.qcService.createPeriodConfig(request).subscribe(resp => {
        window.location.reload();
      }, err => {
        this.periodConfigErrorMessage = err.error.message;
        this.isLoading = false;
      });
    }
  }

  deletePlanItem(item: palexy.sherlock.v1.IQcPlanItem) {
    if (item.isAppliedToVideoTable) {
      alert("Cannot delete item which was already applied to video table!");
      return;
    }
    if(confirm("Are you sure you want to delete this item?")) {
      this.qcService.deletePlanItem(item.name).subscribe(resp => {
        this.qcItems = this.qcItems.filter(qcItem => qcItem.id != item.id);
      });
    }
  }

  deleteConfig(config: palexy.sherlock.v1.QcPeriodConfig) {
    if(confirm("Are you sure you want to delete this config?")) {
      this.isLoading = true;
      this.qcService.deletePeriodConfig(config.name).subscribe(resp => {
        window.location.reload();
      });
    }
  }

  openCreateQcPlanDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    let dialogRef = this.dialog.open(CreateQcPlanDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      newItems => {
        if (newItems) {
          this.qcItems = newItems;
          this.utilsService.showMessage(`Created ${this.qcItems.length} items`);
        }
      }
    )
  }
}


