import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { palexy } from '../../generated/bundle';
import { QcService } from '../qc.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StoreService } from '../../common/store.service';
import { StoreConfigService } from '../../task/storeconfig.service';
import { MatDialog } from '@angular/material/dialog';
import { AccuracyResultDetailDialogComponent } from './accuracy-detail-dialog/accuracy-result-detail-dialog.component';

@Component({
  selector: 'app-qc-file-detail',
  templateUrl: './qc-file-detail.component.html',
  styleUrls: ['./qc-file-detail.component.css']
})
export class QcFileDetailComponent {

  qcFile: palexy.sherlock.v1.IQcFile;
  store: palexy.streaming.v1.Store;
  accuracyResults: palexy.sherlock.v1.IAccuracyResult[] = [];
  accuracyTypeEnum = palexy.sherlock.v1.AccuracyType;
  qcType = palexy.sherlock.v1.QcFile.QcType;
  isLoading = false;

  readonly ACCURACY_TYPE_ORDER: palexy.sherlock.v1.AccuracyType[] = [
    palexy.sherlock.v1.AccuracyType.VISITOR_COUNTING_FOR_WHOLE_DAY,
    palexy.sherlock.v1.AccuracyType.INTERACTION_RATE_FOR_WHOLE_DAY,
    palexy.sherlock.v1.AccuracyType.AGE_DISTRIBUTION_FOR_WHOLE_DAY,
    palexy.sherlock.v1.AccuracyType.GENDER_DISTRIBUTION_FOR_WHOLE_DAY,
    palexy.sherlock.v1.AccuracyType.DWELL_TIME_FOR_WHOLE_DAY,
    palexy.sherlock.v1.AccuracyType.GREETING_RATE_FOR_WHOLE_DAY,
    palexy.sherlock.v1.AccuracyType.TIME_TO_FIRST_GREETING_DISTRIBUTION_FOR_WHOLE_DAY,
    palexy.sherlock.v1.AccuracyType.VISITOR_COUNTING_FOR_1H_SLOT
  ];

  readonly HOURLY_ACCURACY_TYPES: palexy.sherlock.v1.AccuracyType[] = [
    palexy.sherlock.v1.AccuracyType.VISITOR_COUNTING_FOR_1H_SLOT,
    palexy.sherlock.v1.AccuracyType.GROUP_COUNTING_FOR_1H_SLOT,
  ];


  constructor(
    private router: Router,
    storeService: StoreService,
    private qcService: QcService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.isLoading = true;
      const id = params.get('qcFileId');
      this.qcService.getQcFileById(id).subscribe(response => {
        this.qcFile = response.qcFile;
        storeService.getStore(this.qcFile.storeId).subscribe(response => {
          this.store = response;
        });
      });

      this.qcService.getAccuracyResults(id).subscribe(response => {
        this.accuracyResults = response.results;
        this.accuracyResults.sort((a, b) => {
          if (a.accuracyType != b.accuracyType) {
            const aIndex = this.ACCURACY_TYPE_ORDER.indexOf(a.accuracyType);
            const bIndex = this.ACCURACY_TYPE_ORDER.indexOf(b.accuracyType);
            if (aIndex == -1) {
              return 1;
            }
            if (bIndex == -1) {
              return -1;
            }
            return aIndex - bIndex;
          }
          if (a.sectionName != b.sectionName) {
            return a.sectionName.localeCompare(b.sectionName);
          }
          return a.hourSlot - b.hourSlot;
        });
        this.isLoading = false;
      });
    });
  }

  deleteQcFile() {
    if(confirm('Are you sure you want to delete this QC File?')) {
      this.qcService.deleteQcFileById(this.qcFile.id).subscribe(response => {
        this.snackBar.open('Delete succesfully!', null, { duration: 3000,  panelClass: ['snackbar-style']});
        this.router.navigate(['qcfiles']);
      });
    }
  }

  openAccuracyResultDetailDialog(accuracyResult: palexy.sherlock.v1.IAccuracyResult) {
    if (accuracyResult.additionalData.length == 0) {
      return;
    }
    this.dialog.open(AccuracyResultDetailDialogComponent, {
      width: '80vw',
      height: '80vh',
      maxWidth: '80vw',
      maxHeight: '80vh',
      autoFocus: false,
      data: accuracyResult,
    });
  }

}


