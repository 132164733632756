import { Component } from "@angular/core";
import { StoreConfigService } from "../storeconfig.service";
import { StoreConfig } from "../storeconfig.model";
import { RerunMergingDialogComponent } from "../rerun-merging-dialog/rerun-merging-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-store-config-list",
  templateUrl: "./store-config-list.component.html",
  styleUrls: ["./store-config-list.component.css"],
})
export class StoreConfigListComponent {
  displayedColumns: string[] = ["storeName", "configName", "createTime"];
  storeconfigs: StoreConfig[] = [];

  dataSource;

  constructor(
    private dialog: MatDialog,
    private storeConfigService: StoreConfigService
  ) {
    this.storeConfigService.fetchStoreConfigs().subscribe((response) => {
      this.storeconfigs = response.storeConfigs;
      this.dataSource = new MatTableDataSource(this.storeconfigs);
    });
  }

  showRerunMergingView() {
    this.dialog.open(RerunMergingDialogComponent, {
      width: "600px",
      disableClose: true,
      data: {
        store: null,
      },
    });
  }
}
