import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { palexy } from '../../generated/bundle';
import { StoreConfig } from '../storeconfig.model';
import { StoreConfigService } from '../storeconfig.service';
import { StoreService } from '../../common/store.service';
import { filterStore } from '../../common/misc';

@Component({
  selector: 'app-store-config-edit',
  templateUrl: './store-config-edit.component.html',
  styleUrls: ['./store-config-edit.component.css']
})
export class StoreConfigEditComponent {

  storeConfigEditForm: FormGroup;
  isSavingInProgress = false;
  errorMessage = '';
  editStoreConfig: StoreConfig;

  stores: palexy.streaming.v1.IStore[] = [];
  filteredStores: Observable<palexy.streaming.v1.IStore[]>;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private storeService: StoreService,
    private storeConfigService: StoreConfigService,
  ) {
    this.createForm();
    let fetchListStores = this.storeService.fetchStores();
    let fetchStoresConfig = this.storeConfigService.fetchStoreConfigs();
    forkJoin([fetchListStores, fetchStoresConfig]).subscribe(
      responses => {
        let storesWithConfigId: Set<string> = new Set(responses[1].storeConfigs.map(store => store.storeId.toString()));
        this.stores = responses[0].stores.filter(store => !storesWithConfigId.has(store.id.toString()))
        this.filteredStores = this.storeConfigEditForm.get('storeName').valueChanges.pipe(
          startWith(''),
          map(name => name ? filterStore(name, this.stores) : this.stores.slice())
        );
      },
      error => {
        this.showSnackBarMessage(error.error.message);
      }
    )
  }

  ngOnInit() {
    this.editStoreConfig = new StoreConfig();
    this.createForm();
  }

  private createForm() {
    this.storeConfigEditForm = this.fb.group({
      storeId: ['', Validators.required],
      storeName: ['', Validators.required]
    });
  }

  selectStore(store: palexy.streaming.v1.IStore) {
    this.storeConfigEditForm.get('storeId').setValue(store.id);
  }

  private prepareSaveStoreConfig() {
    const formModel = this.storeConfigEditForm.value;
    let newConfig: StoreConfig = {
      ...this.editStoreConfig,
    }
    newConfig.storeId = formModel.storeId as number;
    return newConfig;
  }

  onSubmit() {
    this.errorMessage = '';
    const newStoreConfig = this.prepareSaveStoreConfig();
    console.log(newStoreConfig);
    this.isSavingInProgress = true;
    this.storeConfigService.createStoreConfig(newStoreConfig).subscribe((_) => {
      this.snackBar.open('Config created succesfully', null, { duration: 2000 });
      this.isSavingInProgress = false;
      this.router.navigate(['configs']);
    }, err => {
      this.isSavingInProgress = false;
      this.errorMessage = err.error.message;
    });
  }

  private showSnackBarMessage(message: string, duration: number = 5000) {
    this.snackBar.open(message, 'Close', { duration: duration });
  }
}

