import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { palexy } from '../../generated/bundle';
import { Observable } from 'rxjs';
import { StoreConfig, StoreConfigItem } from '../storeconfig.model';
import { StoreConfigService } from '../storeconfig.service';
import { StoreService } from '../../common/store.service';
import { filterStore } from '../../common/misc';
import { MergingConfigFormComponent } from '../merging-config-form/merging-config-form.component';

@Component({
  selector: 'app-store-config-item-edit',
  templateUrl: './store-config-item-edit.component.html',
  styleUrls: ['./store-config-item-edit.component.css']
})
export class StoreConfigItemEditComponent {
  storeConfigItemEditForm: FormGroup;
  editStoreConfigItem: StoreConfigItem;
  @ViewChild('mergingConfigForm') mergingConfigForm: MergingConfigFormComponent;
  private currentPresetId: number;


  // supporting data
  configName: string;
  currentStore: palexy.streaming.v1.IStore;
  currentStoreConfig: StoreConfig;
  currentStoreConfigItems: StoreConfigItem[];
  currentStoreSuggestedPresets: palexy.streaming.v1.IMergingConfigPreset[] = [];

  isSavingInProgress = false;
  errorMessage = '';

  private stores: palexy.streaming.v1.IStore[] = [];
  private storeConfigs: StoreConfig[];
  private storeIdToConfig: { [key: string]: StoreConfig } = {};

  // copyConfigFromAnotherStore
  copyConfigFromAnotherStoreForm: FormGroup;
  copyConfigFromAnotherStoreFilteredStores: Observable<palexy.streaming.v1.IStore[]>;

  readonly StoreType = palexy.streaming.v1.Store.StoreType;
  readonly CountingType = palexy.streaming.v1.Store.CountingType;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private storeService: StoreService,
    private storeConfigService: StoreConfigService,
  ) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const name = params.get('configName');
      this.configName = 'configs/' + name;
    });
  }

  async ngOnInit() {
    try {
      this.editStoreConfigItem = new StoreConfigItem();
      this.createForm();
      await this.fetchData();
    } catch (error) {
      console.error(error);
      this.snackBar.open(error?.error?.message ?? error, 'Close', { duration: 5 });
    }
  }

  private createForm() {
    this.storeConfigItemEditForm = this.fb.group({
      displayName: ['', Validators.required],
      appliedDate: ['20220101'],
    });
    this.copyConfigFromAnotherStoreForm = this.fb.group({
      storeId: ['', Validators.required],
      storeName: ['', Validators.required]
    });
  }

  private prepareSaveStoreConfig() {
    const formModel = this.storeConfigItemEditForm.value;
    const newConfigItem: StoreConfigItem = {
      ...this.editStoreConfigItem,
      displayName: formModel.displayName,
      configType: 'MERGING_CONFIG',
      appliedScope: "PRODUCTION",
      appliedDate: formModel.appliedDate,
      configData: palexy.streaming.v1.ConfigData.create({
        mergingConfigPreset: palexy.streaming.v1.MergingConfigPreset.create({
          id: this.currentPresetId,
        })
      })
    }
    return newConfigItem;
  }


  onSubmit() {
    this.errorMessage = '';
    const newConfigItem = this.prepareSaveStoreConfig();
    this.isSavingInProgress = true;

    this.storeConfigService.createStoreConfigItem(newConfigItem, this.configName).subscribe((_) => {
      this.snackBar.open('Config item created successfully', null, { duration: 2000 });
      this.isSavingInProgress = false;
      this.router.navigate([this.configName]);
    }, err => {
      this.isSavingInProgress = false;
      this.errorMessage = err.error.message;
    });
  }

  private async fetchData() {
    const [listStoreResp, storeConfigResp, configItemsResp] = await Promise.all([
      this.storeService.fetchStores().toPromise(),
      this.storeConfigService.fetchStoreConfigs().toPromise(),
      this.storeConfigService.fetchConfigItems(this.configName).toPromise(),
    ]);
    this.currentStoreConfigItems = configItemsResp.storeConfigItem;

    this.storeIdToConfig = storeConfigResp.storeConfigs.reduce(
      (accumulator, store) => {
        accumulator[store.storeId] = store;
        return accumulator;
      }, {}
    )
    this.stores = listStoreResp.stores.filter(store => store.id.toString() in this.storeIdToConfig);
    this.storeConfigs = storeConfigResp.storeConfigs;
    this.copyConfigFromAnotherStoreFilteredStores = this.copyConfigFromAnotherStoreForm.get('storeName').valueChanges.pipe(
      startWith(''),
      map(name => name ? filterStore(name, this.stores) : this.stores.slice())
    );

    this.currentStoreConfig = this.storeConfigs.find(storeConfig => storeConfig.name === this.configName);
    this.currentStore = this.stores.find(store => store.id == this.currentStoreConfig.storeId);
    const configPresetResp = await this.storeConfigService.listMergingConfigPreset(this.currentStore.id, true).toPromise();
    this.currentStoreSuggestedPresets = configPresetResp.mergingConfigPresets;

  }

  selectStoreForCopy(store: palexy.streaming.v1.IStore) {
    const t = this.storeIdToConfig[store.id.toString()];
    this.storeConfigService.fetchConfigItems(t.name).subscribe((response) => {
      if (response.storeConfigItem.length > 0) {
        const newestConfigItem = response.storeConfigItem.reduce((a, b) => a.appliedDate > b.appliedDate ? a : b);
        this.setStoreConfigItem(newestConfigItem);
      }
    });
  }

  setStoreConfigItem(item: StoreConfigItem) {
    switch (item.configData.configData) {
      case 'mergingConfigPreset':
        this.setConfigPreset(item.configData.mergingConfigPreset);
        break;
      case 'mergingConfig':
        alert(`The chosen store still use the old embedded merging config. Create a preset from that store first.`);
        break;
      default:
        alert(`Unknown configData case ${item.configData.configData}`);
    }
  }

  setConfigPreset(preset: palexy.streaming.v1.IMergingConfigPreset) {
    this.storeConfigItemEditForm.patchValue({ displayName: preset.name });
    this.mergingConfigForm.setConfig(preset.config);
    this.currentPresetId = preset.id;
  }
}
