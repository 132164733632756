
import { TaskService } from '../task.service';
import { Task } from '../task.model';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { palexy } from '../../generated/bundle';
import { StoreConfig, StoreConfigItem } from '../storeconfig.model';
import { StoreConfigService } from '../storeconfig.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateDebugDataDialogComponent } from './create-debug-data-dialog.component';
import {RerunMergingDialogComponent} from "../rerun-merging-dialog/rerun-merging-dialog.component";
import {CreateDebugExperimentDialogComponent} from "./create-debug-experiment-dialog.component";

@Component({
  selector: 'app-store-config-detail',
  templateUrl: './store-config-detail.component.html',
  styleUrls: ['./store-config-detail.component.css']
})
export class StoreConfigDetailComponent {
  storeConfig: StoreConfig;
  configItems: StoreConfigItem[];
  configName: string;
  allTasks: Task[] = [];
  debugMergeTasks: Task[] = [];
  debugExperimentTasks: Task[] = [];
  accuracyTasks: Task[] = [];
  postMergeTasks: Task[] = [];
  scanningTasks: Task[] = [];
  rerunMergingWithConfigTasks: Task[] = [];

  mergeConfigItems: StoreConfigItem[] = [];
  scanningTaskError: string = '';
  rerunMergingTaskError: string = '';

  constructor(
    private storeConfigService: StoreConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private taskService: TaskService
  ) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const configName = params.get('configName');
      this.configName = 'configs/' + configName
      this.storeConfigService.getStoreConfig('configs/' + configName).subscribe(config => {
        this.storeConfig = config;

        this.taskService.fetchTasks(null, this.storeConfig.id, null, false, 0, 50).subscribe((response) => {
          this.allTasks = response.sherlockTasks;
          this.debugMergeTasks = this.getDebugMergeTasks(this.allTasks);
          this.debugExperimentTasks= this.getDebugExperimentTasks(this.allTasks);
          this.accuracyTasks = this.getAccuracyTasks(this.allTasks);
          this.postMergeTasks = this.getPostMergeTasks(this.allTasks);
          this.scanningTasks = this.getScanningTasks(this.allTasks);
          this.rerunMergingWithConfigTasks = this.getRerunMergingWithConfigTasks(this.allTasks);
        });


      });

      this.storeConfigService.fetchConfigItems('configs/' + configName).subscribe(config => {
        this.configItems = config.storeConfigItem;
        this.mergeConfigItems = this.getMergingConfigItems(this.configItems);
      });
    });
  }

  getMergingConfigItems(configItems: StoreConfigItem[]) {
    return configItems.filter(item => item.configType == 'MERGING_CONFIG');
  }

  getPostMergeConfigItems(configItems: StoreConfigItem[]) {
    return configItems.filter(item => item.configType == 'POST_MERGE_CONFIG');
  }

  showCreateNewConfigItemView() {
    this.router.navigate([this.configName + '/items/new']);
  }

  showCreateDebugDialog() {
    const dialogRef = this.dialog.open(CreateDebugDataDialogComponent, {
      height: '400px',
      width: '600px',
      data: {
        storeConfigId: this.storeConfig.id,
        storeId: this.storeConfig.storeId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showCreateDebugExperimentDialog() {
    const dialogRef = this.dialog.open(CreateDebugExperimentDialogComponent, {
      height: '400px',
      width: '600px',
      data: {
        storeConfigId: this.storeConfig.id,
        storeId: this.storeConfig.storeId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  gotoTaskDetail(task) {
    this.router.navigate([task.name]);
  }

  getCurrentAccuracy() {
    if (confirm('Are you sure?')) {
      let request = palexy.sherlock.v1.CreateAccuracyTasksForStoreConfigRequest.create();
      request.storeConfigId = +this.storeConfig.id;
      this.taskService.createAccuracyTaskForStoreConfig(request).subscribe(_ => {
        location.reload();
      })
    }
  }

  getDebugMergeTasks(tasks: Task[]) {
    return tasks.filter(task => task.taskType == 'TYPE_DEBUG_PERSON');
  }

  getDebugExperimentTasks(tasks: Task[]) {
    return tasks.filter(task => task.taskType == 'TYPE_DEBUG_EXPERIMENT');
  }

  getAccuracyTasks(tasks: Task[]) {
    return tasks.filter(task => task.taskType == 'TYPE_CALCULATE_MERGE_ACCURACY');
  }

  getPostMergeTasks(tasks: Task[]) {
    return tasks.filter(task => task.taskType == 'TYPE_EXECUTE_POST_MERGE_CONFIG');
  }

  getScanningTasks(tasks: Task[]) {
    return tasks.filter(task => task.taskType == 'TYPE_SCAN_MERGE_CONFIG');
  }

  getRerunMergingWithConfigTasks(tasks: Task[]) {
    return tasks.filter(task => task.taskType == 'TYPE_RERUN_MERGE_CONFIG');
  }

  showRerunMergingView() {
    this.dialog.open(RerunMergingDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        store: this.storeConfig.storeData
      }
    });
  }

}


