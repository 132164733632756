import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Task } from '../task.model';
import { TaskService } from '../task.service';

@Component({
  selector: 'app-task-retry-btn',
  templateUrl: './task-retry-btn.component.html',
  styleUrls: ['./task-retry-btn.component.css']
})
export class RetryTaskBtnComponent {

  @Input() task: Task;
  isProcessing= false;

  constructor(
    private taskService: TaskService, 
    private router: Router,
  ) {

  }

  retryTask() {
    if (this.isProcessing) return;
    
    this.isProcessing = true;
    this.taskService.retryTask(this.task.name).subscribe(_ => {
      this.isProcessing = false;
      location.reload();
    });
  }
}


