import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Task } from '../../../task.model';
import { TaskService } from "../../../task.service";

@Component({
  selector: 'app-list-debug-merge',
  templateUrl: './list-debug-merge.component.html',
  styleUrls: ['./list-debug-merge.component.css']
})
export class ListDebugMergeComponent {

  displayedColumns: string[] = ['displayName', 'name', 'taskStatus', 'humanLabelLink', 'actions'];

  dataSource;

  _tasks: Task[] = [];

  @Input()
  set tasks(tasks: Task[]) {
    this._tasks = tasks;
    this.dataSource = new MatTableDataSource(this._tasks);
  }

  constructor(
    private router: Router,
    private taskService: TaskService
  ) {

  }

  selectRow(row) {
    this.router.navigate([row.name]);
  }

  getDownloadLink(link: String) {
    if (link == ''){
      alert('File does not exist!');
      return;
    }

    this.taskService.generateSignedUrl(link).subscribe(resp => {
      window.open(resp.url);
    }, err => {
      alert('Cannot download this file!');
    })
  }

  fetchThenDownloadTrackingResult(task: Task) {
    return this.taskService.getTaskResultOfTask(task.name).subscribe(resp => {
      this.getDownloadLink(resp.taskResults[0].coreTaskResult.debugPersonResult.extractBbResult.trackingDataLink);
    })
  }
}
