
<form class="edit-form" [formGroup]="createDebugDataForm" (ngSubmit)="onSubmit()">
    <div class="flex-container">
        <mat-form-field class="left-flex-field">
            <input formControlName="selectedDate" matInput [matDatepicker]="pickerStart"
                placeholder="Selected Date*">
            <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
            <mat-datepicker #pickerStart></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="left-flex-field">
            <mat-select placeholder="Start Hour" formControlName="startHour" required>
                <mat-option *ngFor="let hour of hours" [value]="hour">
                    {{ hour }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="left-flex-field">
            <mat-select placeholder="End Hour" formControlName="endHour" required>
                <mat-option *ngFor="let hour of hours" [value]="hour">
                    {{ hour }}
                </mat-option>
            </mat-select>
        </mat-form-field>

      <mat-form-field class="left-flex-field">
        <mat-select placeholder="Max Hour to Split" formControlName="maxHoursToSplit" required>
          <mat-option *ngFor="let hour of maxHours" [value]="hour">
            {{ hour }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="field-full-width">
        <mat-select formControlName="experimentName" placeholder="Experiment" required>
          <mat-option *ngFor="let experiment of experiments" [value]="experiment.name">
            {{ experiment.programmaticName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  <button class="main-btn" mat-raised-button color="primary" type="submit" [disabled]="createDebugDataForm.pristine
          || createDebugDataForm.status != 'VALID'
          || isSavingInProgress"> {{(isSavingInProgress) ? "SAVING" : "SAVE"}}</button>
</form>
