import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { palexy } from './generated/bundle';
import { ApiKeyService } from './common/apikey.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  account: palexy.janus.v1.Account;
  doneAuthenticating = false;

  constructor(
    public router: Router,
    private apiKeyService: ApiKeyService
  ) {
    apiKeyService.getCurrentAccountByToken().subscribe(response => {
      this.account = palexy.janus.v1.Account.fromObject(response.account);
      this.doneAuthenticating = true;
    }, err => {
      this.doneAuthenticating = true;
    })
  }

  logout() {
    this.apiKeyService.onUnauthorized();
    this.router.navigate(["/login"]);
  }
}
