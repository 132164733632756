import { Component, OnInit } from "@angular/core";
import { StoreConfigService } from "../storeconfig.service";
import { MatTableDataSource } from "@angular/material/table";
import { palexy } from "../../generated/bundle";
import { ActivatedRoute, Router } from "@angular/router";
import { CompanyService } from "../../common/company.service";
import { mergeMap, tap } from "rxjs/operators";

@Component({
  selector: "app-merging-config-preset-list",
  templateUrl: "./merging-config-preset-list.component.html",
  styleUrls: ["./merging-config-preset-list.component.css"],
})
export class MergingConfigPresetListComponent implements OnInit {
  dataSource =
    new MatTableDataSource<palexy.streaming.v1.IMergingConfigPreset>();
  companies = new Map<number, string>();

  readonly CountingType = palexy.streaming.v1.Store.CountingType;
  readonly StoreType = palexy.streaming.v1.Store.StoreType;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private storeConfigService: StoreConfigService
  ) {}

  async ngOnInit() {
    Promise.all([
      this.storeConfigService
        .listMergingConfigPreset()
        .toPromise()
        .then(
          (presets) => (this.dataSource.data = presets.mergingConfigPresets)
        ),
      this.prepareCompanies(),
    ]);
  }

  readonly displayedColumns = [
    "name",
    "companyIds",
    "storeTypes",
    "countingTypes",
    "note",
    "isEnabledForSuggestion",
    "lastUpdateTimeMs",
    "actions",
  ];

  async onDelete(preset: palexy.streaming.v1.IMergingConfigPreset) {
    if (confirm(`You want to delete preset ${preset.name}?`)) {
      await this.storeConfigService
        .deleteMergingConfigPreset(preset.id)
        .toPromise();
      this.ngOnInit();
    }
  }

  onCopy(preset: palexy.streaming.v1.IMergingConfigPreset) {
    this.router.navigate(["new"], {
      relativeTo: this.route,
      queryParams: { copyFrom: preset.id },
    });
  }

  private async prepareCompanies() {
    await this.companyService
      .getCompanies()
      .pipe(
        mergeMap((companies) => companies.companies),
        tap((comp) => this.companies.set(comp.id, comp.name))
      )
      .toPromise();
  }
}
