
import { TaskService } from '../task.service';
import { Task } from '../task.model';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { palexy } from '../../generated/bundle';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.css']
})
export class TaskDetailComponent {

  // displayedColumns: string[] = ['displayName', 'name', 'taskType', 'taskStatus', 'createTime', 'doneTime'];
  task: Task;

  taskResults: palexy.sherlock.v1.ISherlockTaskResult[] = [];

  dataSource;

  constructor(
    private taskService: TaskService, 
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const name = params.get('taskName');
      this.taskService.getTaskByName('tasks/' + name).subscribe(task => {
        this.task = task;
      });

      this.taskService.getTaskResultOfTask('tasks/' + name).subscribe(taskResultResponse => {
        this.taskResults = taskResultResponse.taskResults;
      });

    });
  }

}


