import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { palexy } from '../generated/bundle';

@Injectable()
export class ExperimentService {
  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }

  listTestSets = (): Observable<palexy.sherlock.v1.ListTestSetsResponse> =>
    this.http
      .get(`/api/sherlock/v1/exp/testsets`)
      .pipe(
        map(resp => palexy.sherlock.v1.ListTestSetsResponse.fromObject(resp))
      );

  createTestSet = (request: palexy.sherlock.v1.CreateTestSetRequest): Observable<palexy.sherlock.v1.CreateTestSetResponse> =>
    this.http
      .post(`/api/sherlock/v1/exp/testsets`, request)
      .pipe(map(resp => palexy.sherlock.v1.CreateTestSetResponse.fromObject(resp)));


  listCaches = (): Observable<palexy.sherlock.v1.ListCachesResponse> =>
    this.http
      .get(`/api/sherlock/v1/exp/caches`)
      .pipe(
        map(resp => palexy.sherlock.v1.ListCachesResponse.fromObject(resp))
      );

  createCache = (request: palexy.sherlock.v1.CreateCacheRequest): Observable<palexy.sherlock.v1.CreateCacheResponse> =>
    this.http
      .post(`/api/sherlock/v1/exp/caches`, request)
      .pipe(map(resp => palexy.sherlock.v1.CreateCacheResponse.fromObject(resp)));

  listTestCases = (): Observable<palexy.sherlock.v1.ListTestCasesResponse> =>
    this.http
      .get(`/api/sherlock/v1/exp/testcases`)
      .pipe(
        map(resp => parseListTestCasesResponse(resp))
      );

  listTestCasesInTestSet = (testSetId): Observable<palexy.sherlock.v1.ListTestCasesResponse> =>
    this.http
      .get(`/api/sherlock/v1/exp/testcases?testSetId=${testSetId}`)
      .pipe(
        map(resp => parseListTestCasesResponse(resp))
      );

  batchCreateTestCases = (request: palexy.sherlock.v1.BatchCreateTestCasesRequest): Observable<palexy.sherlock.v1.BatchCreateTestCasesResponse> =>
    this.http
      .post(`/api/sherlock/v1/exp/testcases:batchCreate`, request)
      .pipe(map(resp => palexy.sherlock.v1.BatchCreateTestCasesResponse.fromObject(resp)));

  addTestCaseToTestSet = (request: palexy.sherlock.v1.AddTestCaseToTestSetRequest): Observable<palexy.sherlock.v1.AddTestCaseToTestSetResponse> =>
    this.http
      .post(`/api/sherlock/v1/exp/${request.testSet}:addTestCase`, request)
      .pipe(map(resp => palexy.sherlock.v1.AddTestCaseToTestSetResponse.fromObject(resp)));

  removeTestCaseFromTestSet = (request: palexy.sherlock.v1.RemoveTestCaseFromTestSetRequest): Observable<palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse> =>
    this.http
      .post(`/api/sherlock/v1/exp/${request.testSet}:removeTestCase`, request)
      .pipe(map(resp => palexy.sherlock.v1.RemoveTestCaseFromTestSetResponse.fromObject(resp)));

  listExperiments = (): Observable<palexy.sherlock.v1.ListExperimentsResponse> =>
    this.http
      .get(`/api/sherlock/v1/exp/experiments`)
      .pipe(
        map(resp => palexy.sherlock.v1.ListExperimentsResponse.fromObject(resp))
      );

  createExperiment = (request: palexy.sherlock.v1.CreateExperimentRequest): Observable<palexy.sherlock.v1.CreateExperimentResponse> =>
    this.http
      .post(`/api/sherlock/v1/exp/experiments`, request)
      .pipe(map(resp => palexy.sherlock.v1.CreateExperimentResponse.fromObject(resp)));
}


function parseListTestCasesResponse(response: any): palexy.sherlock.v1.ListTestCasesResponse {
  let result = palexy.sherlock.v1.ListTestCasesResponse.create();

  if (!('testCases' in response)) {
    return result;
  }
  
  for  (let testCase of response.testCases) {
    let testCaseResult = palexy.sherlock.v1.ExperimentTestCase.create();
    testCaseResult.createTimeMs = testCase.createTimeMs as number;
    testCaseResult.dateId = testCase.dateId;
    testCaseResult.description = testCase.description;
    testCaseResult.id = testCase.id;
    testCaseResult.name = testCase.name;
    testCaseResult.qcFileId = testCase.qcFileId;
    testCaseResult.storeId = testCase.storeId;
    testCaseResult.videos = palexy.sherlock.v1.TestCaseVideos.create();
    
    if ('videos' in testCase.videos) {
      for  (let video of testCase.videos.videos) {
        testCaseResult.videos.videos.push(palexy.streaming.v1.Video.create())
      }
    }

    
    result.testCases.push(testCaseResult);
  }

  result.nextPageToken = response.nextPageToken;

  return result;
}
