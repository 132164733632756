<div *ngIf="isLoading" class="centered">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>

<button mat-raised-button color="secondary" (click)="gotoTestCases()">Test Cases</button>
<button mat-raised-button color="secondary" (click)="gotoTestSets()">Test Sets</button>
<button mat-raised-button color="secondary" (click)="gotoCaches()">Caches</button>

<button mat-raised-button color="primary" (click)="gotoCreateExperiment()">CREATE NEW EXPERIMENT</button>

<mat-table #table [dataSource]="dataSource" class="mat-elevation-z8 task-list-table">
    <ng-container matColumnDef="programmingName">
        <mat-header-cell *matHeaderCellDef> Programmatic Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.programmaticName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cacheId">
        <mat-header-cell *matHeaderCellDef> Cache ID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.cacheId}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{experimentStateEnum[element.state]}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createTime">
        <mat-header-cell *matHeaderCellDef> Create Time </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createTimeMs | date:'medium'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="allInfo">
        <mat-header-cell *matHeaderCellDef> All Other Info </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <ngx-json-viewer [json]="element"></ngx-json-viewer>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>