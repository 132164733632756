import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, Inject } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { palexy } from '../../../generated/bundle';
import { Task } from '../../../task/task.model';
import { TaskService } from '../../../task/task.service';

@Component({
  selector: 'app-qc-video-info-dialog',
  templateUrl: './qc-video-info-dialog.component.html',
  styleUrls: ['./qc-video-info-dialog.component.css']
})
export class QcVideoInfoDialogComponent {
  task: Task;
  store: palexy.streaming.v1.IStore;
  error: string = '';
  qcEmails: string[] = [];

  storageProviderKeys = Object.keys(palexy.sherlock.v1.SherlockTask.StorageProvider);
  storageProviderEnum = palexy.sherlock.v1.SherlockTask.StorageProvider;
  storageProvider = null;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  constructor(
    public dialogRef: MatDialogRef<QcVideoInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private taskService: TaskService,
    private snackBar: MatSnackBar,
  ) {
    this.task = data.task;
    this.store = data.store;
    this.storageProvider = this.task.taskAdvanceInfo.prepareQcVideosAdvanceInfo.storageProvider;
  }

  getVideoUrl(qcVideo: palexy.sherlock.v1.IRemoteFile) {
    if (qcVideo.googleCloudStorageFile != null) {
      return qcVideo.googleCloudStorageFile.downloadableUrl;
    }
    if (qcVideo.googleDriveFile != null) {
      return qcVideo.googleDriveFile.downloadableUrl;
    }
    return null;
  }

  addEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    let email = (value || '').trim();

    if (this.isValidEmail(email)) {
      if ((value || '').trim()) {
        this.qcEmails.push(value.trim());
      }
      if (input) {
        input.value = '';
      }
    }
  }

  removeEmail(email: string): void {
    const index = this.qcEmails.indexOf(email);

    if (index >= 0) {
      this.qcEmails.splice(index, 1);
    }
  }

  isValidEmail(email) {
    const re = /[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(String(email).toLowerCase());
  }

  send() {
    this.dialogRef.close(this.qcEmails);
  }

}


